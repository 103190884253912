import React, { useEffect, useState, useContext } from "react";
import LayoutDashboard from "../../Components/Layout/LayoutDashboard";
import AuthContext from "../../Context/autenticacion/authContext";
import ComponentsBarcode from "./ComponentsBarcode";
import ComponentsTypeSaleClient from "./ComponentsTypesaleClient";
import ProductInfo from "../Sales/ProductInfo";
import ProductsList from "../Sales/ProductsList";
import Button from "@material-ui/core/Button";
import MethodGet from "../../Config/Services";
import headerConfig from "../../Config/imageHeaders";
import { Grid } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Swal from "sweetalert2";
import { MethodPost } from "../../Config/Services";
import fileDownload from "js-file-download";
import clienteAxios from "../../Config/Axios";
import { toast } from "react-toastify";
import ErrorIcon from "@material-ui/icons/Error";
import "react-toastify/dist/ReactToastify.css";
import ModalPaymentMethod from "./ModalPaymentMethod";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  container: {
    marginTop: theme.spacing(11),
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    paddingBottom: theme.spacing(2),
  },
  paperView: {
    marginTop: theme.spacing(2),
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    paddingBottom: theme.spacing(2),
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(9),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginBlockEnd: theme.spacing(1),
  },
  submit: {
    background: "indigo",
    "&:hover": {
      background: "#5902CF",
    },
    color: "white",
  },
  formControl: {
    margin: theme.spacing(1),
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paperModal: {
    position: "absolute",
    width: 500,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  iconButton: {
    "& > svg": {
      margin: theme.spacing(2),
    },
  },
  TableRow: {
    background: "Orange",
  },
  h2: {
    background: "#394ED1",
    color: "white",
  },
}));

function SalesAddProduct(props) {
  const [productsExcel, saveproductsExcel] = useState([]);
  const classes = useStyles();
  const [productoID, guardarProductoID] = useState("");
  const [clienteID, guardarClienteID] = useState("");
  const [subtotal, guardarTotal] = useState(0);
  const [subtotalBrand, SaveSubTotalBrand] = useState(0);
  const [subtotalWithoutBrand, SaveSubTotalWithoutBrand] = useState(0);
  const [subtotalSPA, saveSubTotalSPA] = useState(0);
  const [totalDescuento, guardarTotalDescuento] = useState(0);
  const [totalDiscountBrand, saveTotalDiscountBrand] = useState(0);
  const [totalDiscountWithoutBrand, saveTotalDiscountWithoutBrand] = useState(0);
  const [totalDiscountSPA, saveTotalDiscountSPA] = useState(0);
  const [disableButton, setDisabledButton] = useState(false);
  const [comission, saveComission] = useState(0);
  const [cliente, saveCliente] = useState("");
  const [image, setImage] = useState({
    urlPhoto: "https://pisouniversitario.com/template/images/page-404.jpg",
    image: "",
  });
  const [imageTransfer, setImageTransfer] = useState({
    urlPhoto: "https://pisouniversitario.com/template/images/page-404.jpg",
    image: "",
  });
  const [open, setOpen] = useState(false);
  const [descuentoporcentaje, guardarDescuento] = useState({});
  const [discountWithoutBrand, saveDiscountWithoutBrand] = useState({});
  const [discountSPA, saveDiscountSPA] = useState({});
  const [value, setValue] = useState(0);
  const [productsBranded, saveProductsBranded] = useState([]);
  const [productsWithoutBrand, saveProductsWithoutBrand] = useState([]);
  const [productsSPA, saveProductsSPA] = useState([]);
  const [selectClient, saveSelectClient] = useState([]); 
  const [points, setPoints] = useState(0); 
  const [discountPoints, setDiscountPoints] = useState(0); 
  const [informacionMetodoPago, guardarMetodoPago] = useState({
    amount: "0",
    change: "0",
    cash_income: "0",
    card_income: "0",
    transfer_income: "0",
  });
  const { id } = props;
  const authContext = useContext(AuthContext);
  const { usuario } = authContext;
  const [typeProduct, saveTypeProduct] = useState();
  const [branch, guardarBranch] = useState({});
  const [couponInfo, saveCouponInfo] = useState("");
  const [type_sale, saveTypeSale] = useState("");
  const [business_rule, saveBussinesRule] = useState("");
  const productsOnLocal = localStorage.getItem("EnterProducts")
  const [productsList2, saveproductslist] = useState(productsOnLocal ? JSON.parse(productsOnLocal) : []);
  const [gtotal, saveTotal] = useState([]);
  const [dispPoints, setDispPoints] = useState(0);
  const [error, guardarError] = useState(true);
  const { name } = branch;
  const [isAnyCheckboxSelected, setIsAnyCheckboxSelected] = useState(false);
  const [prodSelectedId, setProdSelectedId] = useState(null);
  const [discountByPoints, setDiscountByPoints] = useState(0);
  
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    guardarMetodoPago({
      amount: 0,
      change: 0,
      cash_income: 0,
      card_income: 0,
      transfer_income: 0,
    });
    setOpen(false);
  };
  const detectarCambiosTypeSale = (value) => {
    saveTypeSale(value.value);
  };
  const obtenerInformacionPago = (e) => {
    guardarMetodoPago({
      ...informacionMetodoPago,
      [e.target.name]: e.target.value,
    });
  };
  const handleChangeImage = (e) => {
    setImage({
      ...image,
      urlPhoto: URL.createObjectURL(e.target.files[0]),
      image: e.target.files[0],
    });
  };
  const handleChangeImageTransfer = (e) => {
    setImageTransfer({
      ...imageTransfer,
      urlPhoto: URL.createObjectURL(e.target.files[0]),
      image: e.target.files[0],
    });
  }; 
  const handleCheckboxChange = (isSelected, selectedId, points) => {
    setIsAnyCheckboxSelected(isSelected);
    setProdSelectedId(selectedId);
    setDiscountPoints(points);
  };
  const handleDiscountByPointsChange = (newDiscount) => {
    setDiscountByPoints(newDiscount);
  };
  useEffect(() => {
    let totalSubTotal =
      Number(subtotalBrand) +
      Number(subtotalWithoutBrand) +
      Number(subtotalSPA);
    guardarTotal(totalSubTotal);
  }, [subtotalBrand, subtotalWithoutBrand, subtotalSPA]);
  useEffect(() => {
    setPoints(totalDescuento * 0.1);
  }, [totalDescuento])
  useEffect(() => {
    saveProductsBranded(productsList2);
  }, [productsList2]);
  useEffect(() => {
    let url = `/branches/${id}`;
    MethodGet(url)
      .then((res) => {
        guardarBranch(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  useEffect(() => {
    const getotals = async () => {
      await clienteAxios
        .get(`branchUtility/${id}`)
        .then((res) => {
          saveTotal(res.data);
        })
        .catch((error) => {
          console.log(error);
        });

      if (props.course) {
        guardarError(false);
      }
    };
    getotals();
  }, []);
  useEffect(() => {
    const ConsultaAPI = () => {
      if (clienteID && business_rule) {
        let urlclient = `/clientsforRule/${clienteID}`;
        MethodGet(urlclient).then((response) => {
          saveCliente(response.data.client);
        });
        let url = `/discountforRule/${business_rule}`;
        MethodGet(url).then((response) => {
          guardarDescuento(response.data);
        });
      }
    };

    ConsultaAPI();
  }, [ clienteID, type_sale,business_rule]);
  useEffect(() => {
    let totalSale =
      Number(totalDiscountBrand) +
      Number(totalDiscountWithoutBrand) +
      Number(totalDiscountSPA);
    if (cliente) {
      guardarTotalDescuento(Math.round(totalSale));
    }
  }, [
    totalDiscountBrand,
    totalDiscountWithoutBrand,
    totalDiscountSPA,
    descuentoporcentaje,
    cliente,
  ]);

  useEffect(() => {
    const getClient = async () => {
      await clienteAxios
        .get(`clients/${clienteID}`)
        .then((res) => {
          saveSelectClient(res.data.data);
        })
        .catch((error) => {
          console.log(error);
        });
    };
    getClient();
  }, [clienteID]);

  useEffect(() => {
    getClientPoints();
  }, [selectClient])
  
  const getClientPoints = async () => {
    try {
        let phone = selectClient.number_phone;        
        const response = await clienteAxios.get(`https://apipoints.wapizima.com/api/points/${phone}`);
        const clienteData = response.data.cliente;  
              
        if (clienteData == null) {
          await clienteAxios.post('https://apipoints.wapizima.com/api/points/create_client', { 
              name: selectClient.name,
              ap_paterno: selectClient.second_lastname,
              ap_materno: selectClient.first_lastname,
              telefono: selectClient.number_phone,
          });
          setDispPoints(clienteData.puntos);
        } else {
            setDispPoints(clienteData.puntos);
        }
        
    } catch (error) {
      console.log(error);
    }
  };
  
  const addPoints = async () => {
    const phone = selectClient.number_phone;
    
    try {
        const response = await clienteAxios.post(`https://apipoints.wapizima.com/api/points/add/${phone}`, {
            puntos: points
        });
        saveSelectClient(response.data.cliente);
    } catch (error) {
        console.error("Error al obtener la información actualizada del cliente:", error.response ? error.response.data : error.message);
    }

  };  

  const decreasePoints = async () => {
    const phone = selectClient.number_phone;

      if (discountPoints > 0 && dispPoints >= discountPoints) {
          await clienteAxios.post(`https://apipoints.wapizima.com/api/points/substract/${phone}`, {
              puntosADescontar: discountPoints 
          });
      }
  };

  const generarVenta = (e) => {
    e.preventDefault();
    if (
      totalDescuento < informacionMetodoPago.amount &&
      informacionMetodoPago.cash_income < totalDescuento
    ) {
      toast.warn(
        <div>
          <ErrorIcon /> No puedes pagar mas del monto total
        </div>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      return;
    }else if (informacionMetodoPago.amount < 0) {
      toast.warn(
        <div>
          <ErrorIcon /> No puedes ingresar valores negativos
        </div>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      return;
  }else if (informacionMetodoPago.card_income > 0){
    if (image.image == "") {
      toast.warn(
        <div>
          <ErrorIcon /> Debes seleccionar la imagen del ticket de pago por
          tarjeta
        </div>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      return;
    }
  } 
    setDisabledButton(true);
    var products_list_branded = JSON.stringify(productsBranded);
    var products_list_withoutBrand = JSON.stringify(productsWithoutBrand);
    var products_list_SPA = JSON.stringify(productsSPA);
    const formData = new FormData();
    formData.append("client_id", clienteID);
    formData.append("user_id", usuario.id);
    formData.append("total", totalDescuento);
    formData.append("totalDiscountBrand", totalDiscountBrand);
    formData.append("totalDiscountWithoutBrand", totalDiscountWithoutBrand);
    formData.append("totalDiscountSPA", totalDiscountSPA);
    formData.append("subtotal", subtotal);
    formData.append("subtotalBrand", subtotalBrand);
    formData.append("subtotalWithoutBrand", subtotalWithoutBrand);
    formData.append("subtotalSPA", subtotalSPA);
    formData.append("previous_balance", gtotal.last_current_balance);
    formData.append("products_list_branded", products_list_branded);
    formData.append("products_list_withoutBrand", products_list_withoutBrand);
    formData.append("products_list_SPA", products_list_SPA);
    formData.append("card_income", informacionMetodoPago.card_income);
    formData.append("cash_income", informacionMetodoPago.cash_income);
    formData.append("transfer_income", informacionMetodoPago.transfer_income);
    formData.append("prodSelectedId", prodSelectedId == null ? 0 : prodSelectedId);
    // formData.append("cange", productCangeableID);
    if (couponInfo != "") {
      formData.append("coupon_id", couponInfo.id);
    }
    if (informacionMetodoPago.card_income > 0) {
      formData.append("image", image.image);
      formData.append("comission", comission);
    }
    if (informacionMetodoPago.transfer_income > 0) {
      formData.append("reference", informacionMetodoPago.reference);
      formData.append("imageTransfer", imageTransfer.image);
    }
    if (
      informacionMetodoPago.cash_income > 0 &&
      informacionMetodoPago.cash_income > totalDescuento
    ) {
      formData.append("change", informacionMetodoPago.change);
    }
    if (totalDiscountBrand != subtotalBrand) {
      formData.append("discount", descuentoporcentaje.discount);
    }
    if (totalDiscountWithoutBrand != subtotalWithoutBrand) {
      formData.append("discountWithoutBrand", discountWithoutBrand.discount);
    }
    if (totalDiscountSPA != subtotalSPA) {
      formData.append("discountSPA", discountSPA.discount);
    }
    if (discountByPoints != 0 || discountByPoints != null || discountByPoints != undefined ) {
      formData.append("discountPoints", discountByPoints);
    }
    let url = "/sales";
    MethodPost(url, formData, { headerConfig })
      .then((res) => {
        let id = res.data.data.id;
        let branchid = res.data.data.branch_office_id;
        let folio = res.data.data.folio;
        let timerInterval;
        Swal.fire({
          title: "Generando",
          html: "El ticket se esta generando",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          willClose: () => {
            clearInterval(timerInterval);
          },
        }).then((result) => {
          //Cunsume API de Back-end en laravel para generar tiket de venta
          clienteAxios
            .get(`/ticket/${id}`, { responseType: "blob" })
            .then((response) => {
              let pdfname = "TicketFolio" + folio + ".pdf";

              fileDownload(response.data, pdfname);
              Swal.fire({
                title: "Buen trabajo",
                text: "Venta registrada exitosamente",
                icon: "success",
                timer: 3000,
                showConfirmButton: false,
              });
              addPoints();
              if (discountPoints != undefined || discountPoints != null && discountPoints > 0 ) {
                decreasePoints();
              }
            })
            .catch((error) => {
              if (error.response.status === 409) {
                Swal.fire({
                  icon: "error",
                  title: "Algo sucedio",
                  text: "No se cuenta con suficiente información para generar el reporte",
                });
                return;
              } else if (error.response.status === 422) {
                console.log(error);
                return;
              }
              return;
            });
        });    
        localStorage.removeItem("EnterProducts")
        props.history.push(`/SucursalVentas/${branchid}`);
      })
      .catch((error) => {
        if (error.response.data.error) {
          setOpen(false);
          setDisabledButton(false);
          toast.error(
            <div>
              <ErrorIcon /> {error.response.data.error}{" "}
            </div>,
            {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );
          guardarMetodoPago({
            amount: 0,
            change: 0,
            cash_income: 0,
            card_income: 0,
            transfer_income: 0,
          });
          return;
        } else {
          setDisabledButton(false);
          toast.error(
            <div>
              <ErrorIcon /> Error inesperado. Si persiste, contacta con el
              administrador{" "}
            </div>,
            {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );
          return;
        }
      });
  };
  
  return (
    <LayoutDashboard>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        style={{
          background: "linear-gradient(50deg, #3f51b5 33%, indigo 100%)",
          color: "white",
          marginTop: 80,
          borderRadius: 15,
          marginBottom: 20,
          marginLeft: 100,
          marginRight: 100,
        }}
      >
        <Typography component="h4" variant="h4" align="center">
          Nueva Venta Sucursal {name}
        </Typography>
      </Grid>
      <Grid container spacing={2}>
        <Grid container spacing={3} xs={12} md={12}>
          <Grid item xs={12} md={12} >
            <ComponentsTypeSaleClient
              clienteID={clienteID}
              guardarClienteID={guardarClienteID}
              id={id}
              saveProductsBranded={saveProductsBranded}
              typeProduct={typeProduct}
              type_sale={type_sale}
              detectarCambiosTypeSale={detectarCambiosTypeSale}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={10} xl={10}  style={{marginTop:"-40px", marginRight:"5px", marginBottom:"10px"}}>
            <ComponentsBarcode
              saveBussinesRule={saveBussinesRule}
              saveTypeProduct={saveTypeProduct}
              typeProduct={typeProduct}
              guardarProductoID={guardarProductoID}
              clienteID={clienteID}
              guardarClienteID={guardarClienteID}
              id={id}
              saveProductsWithoutBrand={saveProductsWithoutBrand}
              saveProductsBranded={saveProductsBranded}
              saveProductsSPA={saveProductsSPA}
              productsBranded={productsBranded}
              productsWithoutBrand={productsWithoutBrand}
              productsSPA={productsSPA}
              type_sale={type_sale}
              detectarCambiosTypeSale={detectarCambiosTypeSale}
              dispPoints={dispPoints}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={2} xl={2} style={{marginLeft:"-2%", marginTop:"-40px"}}>
            <ProductInfo
              productoID={productoID.value}
              clienteID={clienteID}
              saveProductsWithoutBrand={saveProductsWithoutBrand}
              saveProductsBranded={saveProductsBranded}
              saveProductsSPA={saveProductsSPA}
              productsWithoutBrand={productsWithoutBrand}
              productsSPA={productsSPA}
              productsBranded={productsBranded}
              guardarProductoID={guardarProductoID}
              typeProduct={typeProduct}
              setValue={setValue}
              value={0}
            />
          </Grid>
        </Grid>
        <Grid
          container
          spacing={2}
          xs={12}
          md={12}
          style={{ paddingLeft: 10, paddingRight: 10 }}
        >
          <Grid item xs={12} style={{marginTop:"5px"}}>
            <ProductsList
              productsExcel={productsExcel}
              saveProductsWithoutBrand={saveProductsWithoutBrand}
              saveProductsBranded={saveProductsBranded}
              saveProductsSPA={saveProductsSPA}
              productsBranded={productsBranded}
              productsWithoutBrand={productsWithoutBrand}
              productsSPA={productsSPA}
              id={id}
              subtotalBrand={subtotalBrand}
              SaveSubTotalBrand={SaveSubTotalBrand}
              subtotalWithoutBrand={subtotalWithoutBrand}
              subtotalSPA={subtotalSPA}
              saveSubTotalSPA={saveSubTotalSPA}
              SaveSubTotalWithoutBrand={SaveSubTotalWithoutBrand}
              totalDescuento={totalDescuento}
              descuentoporcentaje={descuentoporcentaje}
              discountWithoutBrand={discountWithoutBrand}
              discountSPA={discountSPA}
              totalDiscountBrand={totalDiscountBrand}
              saveTotalDiscountBrand={saveTotalDiscountBrand}
              totalDiscountWithoutBrand={totalDiscountWithoutBrand}
              saveTotalDiscountWithoutBrand={saveTotalDiscountWithoutBrand}
              totalDiscountSPA={totalDiscountSPA}
              saveTotalDiscountSPA={saveTotalDiscountSPA}
              subtotal={subtotal}
              cliente={cliente}
              setValue={setValue}
              value={0}
              dispPoints={dispPoints}
              setDispPoints={setDispPoints}
              onCheckboxChange={handleCheckboxChange}
            />
            <ModalPaymentMethod
              open={open}
              branch={branch}
              saveCouponInfo={saveCouponInfo}
              couponInfo={couponInfo}
              setOpen={setOpen}
              handleClickOpen={handleClickOpen}
              handleClose={handleClose}
              totalDescuento={totalDescuento}
              subtotal={subtotal}
              guardarTotalDescuento={guardarTotalDescuento}
              informacionMetodoPago={informacionMetodoPago}
              image={image}
              imageTransfer={imageTransfer}
              comission={comission}
              saveComission={saveComission}
              clienteID={clienteID}
              obtenerInformacionPago={obtenerInformacionPago}
              generarVenta={generarVenta}
              handleChangeImage={handleChangeImage}
              handleChangeImageTransfer={handleChangeImageTransfer}
              disableButton={disableButton}
              setDiscountPoints={setDiscountPoints}
              dispPoints={dispPoints}
              isAnyCheckboxSelected={isAnyCheckboxSelected}
              discountByPoints={discountByPoints}
              setDiscountByPoints={handleDiscountByPointsChange}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid container spacing={3} justify="center" sx={{ marginTop: 2 }}>
        <Grid item spacing={3} xs={12} md={6} justify="center">
          <Grid item xs={12} justify="center">
            {subtotal > 0 ? (
              <Button
                type="submit"
                fullWidth
                className={classes.submit}
                variant="contained"
                color="primary"
                onClick={handleClickOpen}
              >
                Continuar
              </Button>
            ) : null}
          </Grid>
        </Grid>
      </Grid>
    </LayoutDashboard>
  );
}

export default SalesAddProduct;
