import React , { Fragment , useContext } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { useForm } from  'react-hook-form';
import MethodGet , { MethodDelete, MethodPost , MethodPut} from '../../Config/Services';
import Swal from 'sweetalert2';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ErrorIcon from '@material-ui/icons/Error';
import DoneIcon from '@material-ui/icons/Done';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles(()=> ({

    buttonDiscountCoupon:{
        color:'#fff',
        background:'linear-gradient(50deg, #3f51b5 33%, indigo 100%)',
        border:'none',
        borderRadius:'10px',
        fontSize:'15px',
        padding:'10px'
    },
    couponInput:{
        padding:'10px',
        borderRadius:'4px',
        boxSizing:'border-box',
        marginRight:'5px',
    }
  }));

export default function DiscountCouponForm(props) {
    const classes = useStyles();

    const {saveCouponDiscount, disableCouponButton, saveCouponInfo, SchoolStorageID} = props;

    const onSubmit = (data, e) => {
        e.preventDefault();
        let url = `validateCouponInscription?code=${data.code}&school_id=${SchoolStorageID}`;
        MethodGet(url)
        .then((res) => {
          saveCouponDiscount(res.data.data.discount);
          saveCouponInfo(res.data.data);
          toast.success(<div><DoneIcon/> Cupón Aplicado Exitosamente! </div>, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            });
        })
        .catch((error) => {
            toast.warn(<div><ErrorIcon/> {error.response.data.error} </div>, {
				position: "top-right",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				});
			return;
        });
    }

    const { register ,  errors , handleSubmit  } = useForm();
    return (
        <Fragment>
        <form onSubmit={handleSubmit(onSubmit)}>
        {!disableCouponButton ?
            <>
                <input 
                    className={classes.couponInput}
                    id="code"
                    type="text"
                    name="code"
                    variant="outlined"
                   placeholder="Ingresa el código del cupón"
                   ref={
                    register({
                      required: {value: true , message: 'El código es Requerido'},
                    })
                    }
                />
                <button type="submit" className={classes.buttonDiscountCoupon}>Aplicar Cupón</button>
            </>
            :
            <>
                <button type="submit" disabled className={classes.buttonDiscountCoupon}>Cupón Aplicado</button>
            </>
            }
        </form>
        </Fragment>
    )
}
