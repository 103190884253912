import React, { useState } from "react";
import PropTypes from "prop-types";
import { Box, Typography } from "@material-ui/core";
import BrandSales from "./BrandSales";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const ProductsList = (props) => {
  const {
    productsBranded,
    saveProductsBranded,
    totalDiscountBrand,
    saveTotalDiscountBrand,
    id,
    descuentoporcentaje,
    subtotalBrand,
    SaveSubTotalBrand,
    cliente,
    productsExcel,
    dispPoints,
    setDispPoints,
    onCheckboxChange,
  } = props;
  const [isAnyCheckboxSelected, setIsAnyCheckboxSelected] = useState(false);
  const [prodSelectedId, setProdSelectedId] = useState(null);
  const [prodSelectedPoints, setProdSelectedPoints] = useState(null);
  
  const handleCheckboxChange = (isSelected, selectedId, points) => {    
    setIsAnyCheckboxSelected(isSelected);
    setProdSelectedId(selectedId);
    setProdSelectedPoints(points);
    onCheckboxChange(isSelected, selectedId, points);
};

  return (
    <>
        <BrandSales
          productsExcel={productsExcel}
          productsBranded={productsBranded}
          saveProductsBranded={saveProductsBranded}
          totalDiscountBrand={totalDiscountBrand}
          id={id}
          subtotal={subtotalBrand}
          guardarTotal={SaveSubTotalBrand}
          totalDescuento={totalDiscountBrand}
          guardarTotalDescuento={saveTotalDiscountBrand}
          descuentoporcentaje={descuentoporcentaje}
          cliente={cliente}
          dispPoints={dispPoints}
          setDispPoints={setDispPoints}
          onCheckboxChange={handleCheckboxChange}
        />
    </>
  );
};

export default ProductsList;
