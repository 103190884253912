import React, { useState, useEffect, Fragment, useContext } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import MaterialTable from "material-table";
import { Link } from "react-router-dom";
import ModalImage from "react-modal-image";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { Button, Tooltip, Hidden, IconButton, TextField } from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import ProductsExportExcel from "./ProductsExportExcel";
import ProductsExportExcelempty from "./ProductsExportExcelempty";
import ProductContext from "../../Reducers/Products/ProductContext";
import ModalImportProducts from "./ModalImportProducts";
import ModalUpdateProducts from "./ModalUpdateProducts";
import UpdateIcon from "@material-ui/icons/Update";
import MethodGet from "../../Config/Services";
import Spinner from "../../Complements/Spinner";
import MoreSaledProds from "../Sales/MoreSaledProds";

const useStyles = makeStyles((theme) => ({
  fabGreen: {
    color: theme.palette.common.white,
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[600],
    },
  },
  fabBlue: {
    color: theme.palette.common.white,
    backgroundColor: "#00000",
    "&:hover": {
      backgroundColor: "#00000",
    },
  },
}));

const OfficeProductsAdmin = (props) => {
  const [category, guardarCategory] = useState([]);

  useEffect(() => {
    let url = `/categories`;
    MethodGet(url)
      .then((res) => {
        guardarCategory(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const [subcategory, guardarSubcategory] = useState([]);

  useEffect(() => {
    let url = `/subcategories`;
    MethodGet(url)
      .then((res) => {
        guardarSubcategory(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const classes = useStyles();
  //proveedores = state, guardarProduct =funcion para guardar en el state;
  const [cargando, spinnerCargando] = useState(false);
  const { id } = props; //Se toma el ID de la Sucursal para mostrar sus productos

  const ProductsList = useContext(ProductContext);
  const { products, getProducts, success, DeleteProduct } = ProductsList;

  // const [operator, setOperator] = useState({});
  const [branch, guardarBranch] = useState({});

  //Extraer valores de branch(destructuracion)
  const { name } = branch;

  useEffect(() => {
    getProducts(id);
    spinnerCargando(true);
  }, [success]);

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [openUpdate, setOpenUpdate] = useState(false);

  const handleOpenUpdate = () => {
    setOpenUpdate(true);
  };

  const handleCloseUpdate = () => {
    setOpenUpdate(false);
  };
  //  spinner de c

//   const handler = async(event) => {
//     try {
//         // fetch is available in Node.js 18 and later runtimes
//         const res = await fetch('https://lgi4rj5jr5hh4qxsu6vtzkbokq0afnno.lambda-url.us-east-1.on.aws/');
//         console.info("status", res.status);
//         console.log(res);
        
//         return res.status;
//     }
//     catch (e) {
//         console.error(e);
//         return 500;
//     }
// };

  //obtener los campos de la sucursal
  useEffect(() => {
    let url = `/branches/${id}`;
    MethodGet(url)
      .then((res) => {
        guardarBranch(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const [searchText, setSearchText] = useState('');

  const filteredProducts = products.filter(product =>
    product.name.toLowerCase().includes(searchText.toLowerCase()) ||
    product.tag.toLowerCase().includes(searchText.toLowerCase())
  );

  if (!cargando) return <Spinner />;

  return (
    <>
      {/* titulo */}
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          style={{
            background: "linear-gradient(50deg, #3f51b5 33%, indigo 100%)",
            color: "white",
            marginTop: 10,
            borderRadius: 15,
            marginLeft: 25,
            marginRight: 25,
          }}
        >
          <Typography component="h1" variant="h4">
            {"Productos de la Sucursal " + name}
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            Todos los productos registrados de la sucursal
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          align="right"
          style={{ marginRight: 30, marginTop: -30 }}
        >
            <MoreSaledProds id={id} />
            <Fab
              className={classes.fabGreen}
              color="primary"
              aria-label="add"
              size="medium"
              href={"/Agregar_Producto/" + id}
            >
              <Tooltip
                title={"Agregar Producto Sucursal " + name}
                aria-label={"Agregar Producto Sucursal " + name}
              >
                <AddIcon size="large" />
              </Tooltip>
            </Fab>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} md={6} style={{ padding: 5 }}>
          <Button
            variant="outlined"
            onClick={handleOpen}
            startIcon={<CloudUploadIcon />}
            fullWidth
            color="primary"
            style={{ margin: 5 }}
          >
            Importar Nuevos P.
          </Button>
          <ProductsExportExcelempty />
        </Grid>
        {products && products.length !== 0 ? (
          <Grid item xs={12} md={6} style={{ padding: 5 }}>
            <Button
              variant="outlined"
              onClick={handleOpenUpdate}
              startIcon={<UpdateIcon />}
              fullWidth
              color="primary"
              style={{ margin: 5 }}
            >
              Actualizar P. Existentes
            </Button>
            <ProductsExportExcel id={id} />
          </Grid>
        ) : null}
      </Grid>
      {/**
       * Se renderiza el titulo y los botones de las vista de tabla asi como el ajuste de mobile *-
       *
       */}
      {/* Pantallas grandes */}
      <Hidden only={["xs", "sm"]}> 
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          style={{ marginRight: 30, marginLeft: 30, marginTop: 10 }}
        >
          <MaterialTable
            title={""}
            columns={[
              { title: "Clave", field: "tag" },
              {
                title: "Imagen",
                field: "image",
                render: (rowData) =>
                  rowData.image ? (
                    <ModalImage
                      small={rowData.image}
                      large={rowData.image}
                      alt="producto"
                    />
                  ) : (
                    <ModalImage
                      small={
                        "https://pisouniversitario.com/template/images/page-404.jpg"
                      }
                      large={
                        "https://pisouniversitario.com/template/images/page-404.jpg"
                      }
                      alt="producto"
                    />
                  ),
              },
              { title: "Nombre", field: "name" },
              { title: "Descripción", field: "description" },
              /* { title: "Ubicacion", field: "ubication" }, */
              // { title: "Categoria", field: "categoria" },
              // { title: "Subategoria", field: "subcategoria" },
              {
                title: "Precio P.",
                field: "price",
                render: (rowData) =>
                  new Intl.NumberFormat("es-MX", {
                    style: "currency",
                    currency: "MXN",
                    minimumFractionDigits: 0,
                  }).format(rowData.price),
              },
              {
                title: "Precio C.",
                field: "price_purchase",
                render: (rowData) =>
                  new Intl.NumberFormat("es-MX", {
                    style: "currency",
                    currency: "MXN",
                    minimumFractionDigits: 0,
                  }).format(rowData.price_purchase),
              },
              // {
              //   title: "Precio M.",
              //   field: "price_retail",
              //   render: (rowData) =>
              //     new Intl.NumberFormat("es-MX", {
              //       style: "currency",
              //       currency: "MXN",
              //       minimumFractionDigits: 0,
              //     }).format(rowData.price_retail),
              // },
              // {
              //   title: "Precio 1",
              //   field: "price1",
              //   render: (rowData) =>
              //     new Intl.NumberFormat("es-MX", {
              //       style: "currency",
              //       currency: "MXN",
              //       minimumFractionDigits: 0,
              //     }).format(rowData.price1),
              // },
              // {
              //   title: "Precio 2",
              //   field: "price2",
              //   render: (rowData) =>
              //     new Intl.NumberFormat("es-MX", {
              //       style: "currency",
              //       currency: "MXN",
              //       minimumFractionDigits: 0,
              //     }).format(rowData.price2),
              // },
              { title: "Cantidad", field: "quantity" },
            ]}
            data={products}
            options={{
              headerStyle: {
                backgroundColor: "none",
                color: "#000",
                fontSize: "16px",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
              },

              actionsColumnIndex: -1,
            }}
            actions={[
              (rowData) => ({
                icon: "delete",
                tooltip: "Eliminar Producto",
                onClick: () => DeleteProduct(rowData.id),
              }),
            ]}
            components={{
              Action: (props) => (
                <Fragment>
                  <Link
                    to={`/Editar_Producto/${props.data.id}`}
                    style={{ color: "#3f51b5" }}
                  >
                    <Button
                      variant="contained"
                      style={{
                        textTransform: "none",
                        background: "white",
                      }}
                      size="small"
                    >
                      <Tooltip
                        title="Editar Producto"
                        aria-label="Editar Producto"
                      >
                        <EditIcon style={{ color: "blue" }} />
                      </Tooltip>
                    </Button>
                  </Link>
                  <Button
                    variant="contained"
                    style={{ textTransform: "none", background: "white" }}
                    size="small"
                    onClick={() => DeleteProduct(props.data.id)}
                  >
                    <Tooltip
                      title="Eliminar Producto"
                      aria-label="Editar Producto"
                    >
                      <DeleteIcon style={{ color: "red" }} />
                    </Tooltip>
                  </Button>
                </Fragment>
              ),
            }}
            localization={{
              pagination: {
                labelDisplayedRows: "{from} - {to} de {count}",
                labelRowsSelect: "Columnas",
                firstTooltip: "Primera Pagina",
                previousTooltip: "Anterior",
                nextTooltip: "Siguiente",
                lastTooltip: "Ultima Página",
              },
              toolbar: {
                searchTooltip: "Buscar",
                searchPlaceholder: "Buscar",
              },
              header: {
                actions: "Opciones",
              },
              body: {
                emptyDataSourceMessage: "No Hay Productos que Mostrar",
                filterRow: {
                  filterTooltip: "Buscar",
                },
              },
            }}
          />
        </Grid>
      </Hidden>
      {/* Para pantallas pequeñas */}
      <Hidden only={["md", "lg", "xl"]}> 
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          style={{ marginTop: 10 }}
        >
          <TextField
            label="Buscar nombre o clave"
            variant="outlined"
            fullWidth
            style={{ marginTop: 10 }}
            value={searchText}
            onChange={e => setSearchText(e.target.value)}
          />
          <MaterialTable
            title=""
            columns={[
              { title: "Clave", field: "tag" },
              {
                title: "Imagen",
                field: "image",
                render: rowData =>
                  rowData.image ? (
                    <ModalImage
                      small={rowData.image}
                      large={rowData.image}
                      alt="producto"
                    />
                  ) : (
                    <ModalImage
                      small="https://cdn.pngsumo.com/index-of-areaedu-wp-content-uploads-2016-02-default-png-600_600.png"
                      large="https://cdn.pngsumo.com/index-of-areaedu-wp-content-uploads-2016-02-default-png-600_600.png"
                      alt="producto"
                    />
                  ),
              },
              // Agrega aquí otras columnas si es necesario
            ]}
            data={filteredProducts}
            options={{
              headerStyle: {
                backgroundColor: "none",
                color: "#000",
                fontSize: "16px",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
              },
              actionsColumnIndex: -1,
              search: false, // Desactiva la búsqueda por defecto
            }}
            detailPanel={[
              {
                tooltip: "Show",
                render: rowData => (
                  <div
                    style={{
                      color: "black",
                      marginLeft: 50,
                    }}
                  >
                    <p>Nombre: {rowData.name}</p>
                    <p>Descripcion: {rowData.description}</p>
                    <p>Precio Publico: ${rowData.price}</p>
                    <p>Precio Costo: ${rowData.price_purchase}</p>
                    <p>Cantidad: {rowData.quantity}</p>
                    <p>Opciones: </p>
                    <p>
                      <Fragment>
                        <Link to={`/Editar_Producto/${rowData.id}`}>
                          <IconButton
                            variant="contained"
                            style={{
                              textTransform: "none",
                              background: "white",
                              marginLeft: 50,
                            }}
                            size="small"
                          >
                            <Tooltip
                              title="Editar Producto"
                              aria-label="Editar Producto"
                            >
                              <EditIcon style={{ color: "blue" }} />
                            </Tooltip>
                          </IconButton>
                        </Link>
                        <IconButton
                          variant="contained"
                          style={{
                            textTransform: "none",
                            background: "white",
                            marginLeft: 100,
                          }}
                          size="small"
                          onClick={() => DeleteProduct(rowData.id)}
                        >
                          <Tooltip
                            title="Eliminar Producto"
                            aria-label="Eliminar Producto"
                          >
                            <DeleteIcon style={{ color: "red" }} />
                          </Tooltip>
                        </IconButton>
                      </Fragment>
                    </p>
                  </div>
                ),
              },
            ]}
            localization={{
              pagination: {
                labelDisplayedRows: "{from} - {to} de {count}",
                labelRowsSelect: "Columnas",
                firstTooltip: "Primera Página",
                previousTooltip: "Anterior",
                nextTooltip: "Siguiente",
                lastTooltip: "Última Página",
              },
              toolbar: {
                searchTooltip: "Buscar",
                searchPlaceholder: "Buscar por nombre",
              },
              body: {
                emptyDataSourceMessage: "No Hay Productos que Mostrar",
                filterRow: {
                  filterTooltip: "Buscar",
                },
              },
            }}
          />
        </Grid>

      </Hidden>
      <ModalImportProducts
        open={open}
        handleOpen={handleOpen}
        handleClose={handleClose}
        spinnerCargando={spinnerCargando}
        id={id}
      />
      <ModalUpdateProducts
        open={openUpdate}
        handleOpen={handleOpenUpdate}
        handleClose={handleCloseUpdate}
        spinnerCargando={spinnerCargando}
        id={id}
      />
    </>
  );
};

export default OfficeProductsAdmin;
