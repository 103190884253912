import React, { useState, useEffect, useContext } from "react";
import ChangeTypeSale from "./ChangeTypeSale";
import SelectBussinesRule from "../SelectOptions/SelectBussinesRule";
import useSelectProductsPerBrand from "../SelectOptions/SelectProductsPerBrand";
import Spinner from "../../Complements/Spinner";
import {
  Grid,
  TextField,
} from "@material-ui/core";
import { useForm } from "react-hook-form";
import { useDebounce } from "use-debounce";
import ProductContext from "../../Reducers/Products/ProductContext";
import InputAdornment from "@material-ui/core/InputAdornment";
import Swal from "sweetalert2";
import CropFreeIcon from "@material-ui/icons/CropFree";

const SelectsComponent = ({
  saveBussinesRule,
  guardarProductoID,
  guardarClienteID,
  id,
  clienteID,
  productsBranded,
  productsWithoutBrand,
  productsSPA,
  saveProductsSPA,
  saveProductsWithoutBrand,
  saveProductsBranded,
  type_sale,
  saveTypeProduct,
  typeProduct,
  dispPoints
}) => {
  const { register, errors } = useForm();
  const [productID, SelectProduct] = useSelectProductsPerBrand({
    stateInicial: "",
    branchID: id,
    typeSale: type_sale,
    dispPoints
  });
  // const [client, SelectClient] = useSelectClient({
  //   stateInicial: "",
  //   branchID: id,
  // });

  const [client, saveClient] = useState();
  const [cargando, spinnerCargando] = useState(false);
  const [barcode, setBarCode] = useState("");
  const [debouncedBarcode] = useDebounce(barcode, 500);
  const ProductContextList = useContext(ProductContext);
  const { getSaleProductsPerBrand, products } = ProductContextList;
  const [error, guardarError] = useState(true);
  const detectarCambiosTypeProduct = (value) => {
    saveTypeProduct(value.value);
  };

  const detectarCambiosRuleBranch = (selectedOption) => {
    if (selectedOption) {
      guardarError(false);
    }
    // Guardamos el valor seleccionado directamente
    saveBussinesRule(selectedOption ? selectedOption.value : null);
  };

  //cliente
  useEffect(() => {
    spinnerCargando(true);
    guardarClienteID(client);
  }, [client]);

  useEffect(() => {
    getSaleProductsPerBrand(id, type_sale);
  }, [type_sale]);

  const handleChangeCodeBar = (event) => {
    setBarCode(event.target.value);
  };

  useEffect(() => {
    filterProductExist();
  }, [debouncedBarcode, productID]);

  const filterProductExist = () => {
    if (debouncedBarcode != "" || productID) {
      var product = [];
      if (typeProduct == 1 && debouncedBarcode == "") {
        return;
      }
      if (typeProduct == 1) {
        product = products.filter((p) => p.tag == debouncedBarcode);
      } else {
        product = products.filter((p) => p.id == productID.value);
        var existBranded = productsBranded.filter(
          (p) => p.product_id === productID.value
        );
        var existSPA = productsSPA.filter(
          (p) => p.product_id === productID.value
        );
        var existWioutBrand = productsWithoutBrand.filter(
          (p) => p.product_id === productID.value
        );
        if (existBranded.length || existSPA.length || existWioutBrand.length) {
          Swal.fire({
            title: "No permitido",
            icon: "error",
            text: "El producto ya ha sido agregado a la lista de productos, por favor modifica la cantidad si deseas agregar más productos!",
            timer: 2000,
            showConfirmButton: false,
          });
          return;
        }
      }
      if (!product.length) {
        Swal.fire({
          title: "¡Lo sentimos!",
          text: "El Código de barras no coincide con ningún producto de la lista",
          icon: "error",
          showConfirmButton: false,
          timer: 2000,
        });
        setBarCode("");
        return;
      }
      if (clienteID == undefined) {
        Swal.fire({
          title: "Antes de continuar",
          text: "Selecciona un cliente",
          icon: "error",
          timer: 2500,
          showConfirmButton: false,
        });
        setBarCode("");
        return;
      }

      product[0].value = product[0].id;
      guardarProductoID(product[0]);
      let productoResultado = {};
      productoResultado.description = product[0].description;
      productoResultado.image = product[0].image;
      productoResultado.name = product[0].name;
      productoResultado.price = product[0].price;
      productoResultado.price_purchase = product[0].price_purchase;
      productoResultado.quantity = product[0].quantity;
      productoResultado.tag = product[0].tag;
      productoResultado.value = product[0].value;
      productoResultado.type = Number(product[0].type);
      var position = -1;
      if (productoResultado.type === 1 && productsWithoutBrand.length !== 0) {
        position = productsWithoutBrand
          .map(function (e) {
            return e.product_id;
          })
          .indexOf(product[0].id);
      }
      if (productoResultado.type === 2 && productsBranded.length !== 0) {
        position = productsBranded
          .map(function (e) {
            return e.product_id;
          })
          .indexOf(product[0].id);
      }
      if (productoResultado.type === 3 && productsSPA.length !== 0) {
        position = productsSPA
          .map(function (e) {
            return e.product_id;
          })
          .indexOf(product[0].id);
      }
      if (position == -1 && typeProduct == 1) {
        //generar una llave unica para cada producto
        productoResultado.product_id = product[0].id;
        //generar cantidad productos por adquirir
        productoResultado.cantidad = 1;
        //generar precio final por producto
        productoResultado.final_price =
          productoResultado.cantidad * Number(product[0].price);
        //guardarlo en el state
        if (productoResultado.type === 1) {
          saveProductsWithoutBrand([
            productoResultado,
            ...productsWithoutBrand,
          ]);
        }
        if (productoResultado.type === 2) {
          saveProductsBranded([productoResultado, ...productsBranded]);
        }
        if (productoResultado.type === 3) {
          saveProductsSPA([productoResultado, ...productsSPA]);
        }
      } else if (typeProduct == 1) {
        if (productoResultado.type === 1) {
          let quantity_product = productsWithoutBrand[position].cantidad;
          let new_quantity_product = (quantity_product += 1);
          if (product[0].quantity < new_quantity_product) {
            Swal.fire({
              title: "¡Lo sentimos!",
              html: `<p>
              No quedán mas productos en stock <b>
              ${product[0].name} 
              </b> 
              </p>
              ` ,
              icon: "error",
              showConfirmButton: false,
              timer: 2000,
            });
            setBarCode("");
            return;
          }
          productsWithoutBrand[position].cantidad = new_quantity_product;
          productsWithoutBrand[position].final_price =
            productsWithoutBrand[position].cantidad * Number(product[0].price);
          saveProductsWithoutBrand([...productsWithoutBrand]);
        }
        if (productoResultado.type === 2) {
          let quantity_product = productsBranded[position].cantidad;
          let new_quantity_product = (quantity_product += 1);
          if (product[0].quantity < new_quantity_product) {
            Swal.fire({
              title: "¡Lo sentimos!",
              html: `<p>
              No quedán mas productos en stock <b>
              ${product[0].name} 
              </b> 
              </p>
              ` ,
              icon: "error",
              showConfirmButton: false,
              timer: 3500,
            });
            setBarCode("");
            return;
          }
          productsBranded[position].cantidad = new_quantity_product;
          productsBranded[position].final_price =
            productsBranded[position].cantidad * Number(product[0].price);
          saveProductsBranded([...productsBranded]);
        }
        if (productoResultado.type === 3) {
          let quantity_product = productsSPA[position].cantidad;
          let new_quantity_product = (quantity_product += 1);
          if (product[0].quantity < new_quantity_product) {
            Swal.fire({
              title: "¡Lo sentimos!",
              html: `<p>
              No quedán mas productos en stock <b>
              ${product[0].name} 
              </b> 
              </p>
              ` ,
              icon: "error",
              showConfirmButton: false,
              timer: 2000,
            });
            setBarCode("");
            return;
          }
          productsSPA[position].cantidad = new_quantity_product;
          productsSPA[position].final_price =
            productsSPA[position].cantidad * Number(product[0].price);
          saveProductsSPA([...productsSPA]);
        }
      }

      setBarCode("");
    }
  };

  if (!cargando) return <Spinner />;

  return (
    <Grid container style={{marginBottom:"10px", marginLeft:"10px"}}>
      <Grid item xs={12} sm={12} md={4} style={{paddingRight:"2px", marginTop:"7px"}}>
        <SelectBussinesRule idbranch={id} detectarCambiosRuleBranch={detectarCambiosRuleBranch}/>
      </Grid>
      <Grid item xs={12} sm={12} md={4} style={{paddingRight:"2px", marginTop:"7px"}}>
        <ChangeTypeSale
          detectarCambiosTypeProduct={detectarCambiosTypeProduct}
        />
      </Grid>
      <Grid
        item
        xs={12} 
        sm={12}
        md={4} style={{paddingRight:"2px", marginTop:"5px"}}
        justify="center"
        alignContent="center"
        alignItems="center"
      >
        {typeProduct == 1 ? (
          <>
            <TextField
              placeholder="Usa el lector de codigos de barra"
              variant="outlined"
              required
              id="barcode"
              label="Código De Barras"
              name="barcode"
              value={barcode}
              onChange={handleChangeCodeBar}
              autoComplete="barcode"
              autoFocus
              fullWidth
              margin="dense"
              error={!!errors.barcode}
              style={{marginTop:"-1px"}}
              inputRef={register({
                required: {
                  value: true,
                  message: "El código de barras es requerido",
                },
                minLength: {
                  value: 4,
                  message: "Minimo 4 caracteres",
                },
                maxLength: {
                  value: 45,
                  message: "Maximo 45 caracteres",
                },
              })}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <CropFreeIcon />
                  </InputAdornment>
                ),
              }}
            />
            <p>{errors?.barcode?.message}</p>{" "}
          </>
        ) : (null)}
        {typeProduct == 2 ? (
          <>
            <SelectProduct id={id} />
          </>
        ) : (null)}
      </Grid>
    </Grid>
  );
};

export default SelectsComponent;
