import React, { useState, useEffect } from "react";
import LayoutDashboard from "../../Components/Layout/LayoutDashboard";
import {
  Grid,
  Tooltip,
  Button,
  TextField,
  IconButton,
  InputLabel,
  FormControl,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import { useForm } from "react-hook-form";
import headerConfig from "../../Config/imageHeaders";
import MethodGet, { MethodPost } from "../../Config/Services";
import Swal from "sweetalert2";
import Spinner from "../../Complements/Spinner";
import { withRouter } from "react-router-dom";
import SelectBranchOffice from "../SelectOptions/SelectBranchOffice";
import GridItem from "../../Components/Grid/GridItem";
import Card from "../../Components/Card/Card";
import CardHeader from "../../Components/Card/CardHeader";
import CardAvatar from "../../Components/Card/CardAvatar";
import CardBody from "../../Components/Card/CardBody";
import SelectCategories from "../SelectOptions/SelectCategory";
import SelectSubCategory from "../SelectOptions/SelectSubCategory";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(10),
    //marginBottom: theme.spacing(10),
  },
  typography: {
    margin: theme.spacing(1),
    //paddingLeft: theme.spacing(30),
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(9),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginBlockEnd: theme.spacing(1),
  },
  submit: {
    background: "indigo",
    "&:hover": {
      background: "#5902CF",
    },
    color: "white",
  },
  formControl: {
    margin: theme.spacing(1),
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  input: {
    display: "none",
  },
  containerImageProfile: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-around",
  },
  PaperFormAdd: {
    background: "white",
    color: "black",
    boxShadow: "2px 4px 4px #cacaca",
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
}));

function ProductEdit(props) {
  const classes = useStyles();
  const [cargando, spinnerCargando] = useState(false);

  const { id } = props.match.params;

  //Funcion para validaciones
  const { register, handleSubmit, errors } = useForm();

  // const [operator, setOperator] = useState({});
  const [product, guardarProduct] = useState({});
  const [type_product, setTypeProduct] = useState(false);

  const detectarCambiosTypeProduct = (e) => {
    setTypeProduct(2);
  };
  const [category, setCategory] = useState(null);

  const detectarCambiosCategory = (e) => {
    setCategory(e.target.value);
  };
  const [subcategory, setSubCategory] = useState(null);
  const detectarCambiosSubCategory = (e) => {
    setSubCategory(e.target.value);
  };
  //Trabajar con el state de office
  const [officeId, saveOffice] = useState({
    id_branch_office: "",
  });
  const [isChangeable, setIsChangeable] = useState(false);
  const [points, setPoints] = useState(0);

  const handleCheckboxChange = (event) => {
    setIsChangeable(event.target.checked);
  };

  const {
    tag,
    name,
    description,
    price,
    price_purchase,
    quantity,
    price_retail,
  } = product;

  const detectarCambiosOffice = (e) => {
    saveOffice({ id_branch_office: e.target.value });
  };

  const [image, setImage] = useState({
    urlPhoto: "https://pisouniversitario.com/template/images/page-404.jpg",
    image: "",
  });

  //funcion para guardar la imagen y obtener la vista previa
  const handleChangeImage = (e) => {
    setImage({
      ...image,
      urlPhoto: URL.createObjectURL(e.target.files[0]),
      image: e.target.files[0],
    });
  };

  useEffect(() => {
    let url = `/products/${id}`;

    MethodGet(url)
      .then((res) => {
        guardarProduct(res.data.data);
        saveOffice({ id_branch_office: res.data.data.id_branch_office });
        setTypeProduct(res.data.data.type);
        setSubCategory(res.data.data.id_subcategories);
        setCategory(res.data.data.id_categories);
        setIsChangeable(res.data.data.changeable);
        setPoints(res.data.data.points);
        if (res.data.data.image) {
          setImage({ urlPhoto: res.data.data.image, image: "" });
        }
        spinnerCargando(true);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  const onSubmit = (data, e) => {
    e.preventDefault();
    data.category = category;
    data.subcategory = subcategory;
    //crear un formdata
    const formData = new FormData();
    formData.append("tag", data.tag);
    formData.append("name", data.name);
    formData.append("price", data.price);
    formData.append("price_purchase", data.price_purchase);

    formData.append("description", data.description);
    formData.append("ubication", data.ubication);
    formData.append("quantity", data.quantity);
    formData.append("type", 2);
    formData.append("id_branch_office", officeId.id_branch_office);
    formData.append("changeable", isChangeable ? 1 : 0);
    formData.append("points", data.points ?? 0);
    if (image.image !== "") {
      formData.append("image", image.image);
    }
    formData.append("_method", "PATCH");
    let url = `/products/${id}`; //cambiar la url completa
    MethodPost(url, formData, { headerConfig })
      .then((res) => {
        Swal.fire({
          title: "Producto Editado Exitosamente",
          text: res.data.message,
          icon: "success",
          timer: 2000,
          showConfirmButton: false,
        });
        props.history.push("/SucursalProductos/" + officeId.id_branch_office);
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.error,
          icon: "error",
        });
      });
  };

  //  spinner de carga
  if (!cargando) return <Spinner />;

  return (
    <LayoutDashboard>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        {/**
         * identificar Codigo de FORMS
         */}
        <Grid
          container
          justifyContent='center'
          alignItems='center'
          style={{ marginTop: 70 }}
        >
          {/**
           * identificar Codigo de FORMS
           */}
          <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
            <Card>
              <CardHeader color='primary'>
                <h2 className={classes.cardTitleWhite}>
                  Edición Del Producto {tag}
                </h2>
                <p className={classes.cardCategoryWhite}>
                  Completa correctamente los datos
                </p>
              </CardHeader>
              <CardBody>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={4} style={{ paddingTop: 16 }}>
                    <FormControl
                      variant='outlined'
                      className={classes.formControl}
                    >
                      <SelectBranchOffice
                        officeId={officeId}
                        detectarCambiosOffice={detectarCambiosOffice}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <TextField
                      variant='outlined'
                      margin='normal'
                      required
                      fullWidth
                      id='tag'
                      label='Clave'
                      name='tag'
                      autoComplete='tag'
                      autoFocus
                      defaultValue={tag}
                      error={!!errors.tag}
                      inputRef={register({
                        required: {
                          value: true,
                          message: "La Clave es requerida",
                        },
                        minLength: {
                          value: 1,
                          message: "Minimo 1 caracteres",
                        },
                        maxLength: {
                          value: 45,
                          message: "Maximo 45 caracteres",
                        },
                      })}
                    />
                    <p>{errors?.tag?.message}</p>
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <TextField
                      variant='outlined'
                      margin='normal'
                      required
                      fullWidth
                      id='name'
                      label='Nombre'
                      name='name'
                      autoComplete='name'
                      autoFocus
                      defaultValue={name}
                      error={!!errors.name}
                      inputRef={register({
                        required: {
                          value: true,
                          message: "El Nombre es requerido",
                        },
                        minLength: {
                          value: 1,
                          message: "Minimo 1 caracter",
                        },
                        maxLength: {
                          value: 255,
                          message: "Maximo 255 caracteres",
                        },
                      })}
                    />
                    <p>{errors?.name?.message}</p>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      variant='outlined'
                      margin='normal'
                      fullWidth
                      id='quantity'
                      label='Cantidad'
                      name='quantity'
                      type='number'
                      defaultValue={quantity}
                      autoFocus
                      error={!!errors.quantity}
                      inputRef={register({
                        maxLength: {
                          value: 5,
                          message: "Maximo 5 digitos",
                        },
                      })}
                    />
                    <p>{errors?.quantity?.message}</p>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      variant='outlined'
                      margin='normal'
                      required
                      fullWidth
                      id='price_purchase'
                      label='Precio Costo'
                      name='price_purchase'
                      autoComplete='name'
                      autoFocus
                      defaultValue={price_purchase}
                      error={!!errors.price_purchase}
                      inputRef={register({
                        required: {
                          value: true,
                          message: "El Precio Costo es requerido",
                        },
                        maxLength: {
                          value: 10,
                          message: "Maximo 10 caracteres",
                        },
                      })}
                    />
                    <p>{errors?.price_purchase?.message}</p>
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <TextField
                      variant='outlined'
                      margin='normal'
                      required
                      fullWidth
                      id='price'
                      label='Precio venta'
                      name='price'
                      type='number'
                      autoFocus
                      defaultValue={price}
                      error={!!errors.price}
                      inputRef={register({
                        required: {
                          value: true,
                          message: "El precio venta es requerido",
                        },
                        maxLength: {
                          value: 10,
                          message: "Maximo 10 caracteres",
                        },
                      })}
                    />
                    <p>{errors?.price?.message}</p>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      variant='outlined'
                      margin='normal'
                      fullWidth
                      id='price_retail'
                      label='Precio menudeo'
                      name='price_retail'
                      type='number'
                      autoFocus
                      defaultValue={price_retail}
                      error={!!errors.price_retail}
                      inputRef={register({
                        // required: {
                        //   value: true,
                        //   message: "El precio menudeo es requerido",
                        // },
                        maxLength: {
                          value: 10,
                          message: "Maximo 10 caracteres",
                        },
                      })}
                    />
                    <p>{errors?.price_retail?.message}</p>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant='outlined'
                      margin='normal'
                      required
                      fullWidth
                      id='description'
                      label='Descripción'
                      name='description'
                      autoComplete='name'
                      autoFocus
                      defaultValue={description}
                      error={!!errors.description}
                      inputRef={register({
                        required: {
                          value: true,
                          message: "La Descripción es requerido",
                        },
                        minLength: {
                          value: 1,
                          message: "Minimo 1 caracter",
                        },
                        maxLength: {
                          value: 255,
                          message: "Maximo 255 caracteres",
                        },
                      })}
                    />
                    <p>{errors?.description?.message}</p>
                  </Grid>
                  {/* <Grid item xs={12} sm={4}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isChangeable}
                          onChange={handleCheckboxChange}
                          color='primary'
                        />
                      }
                      label='¿Es canjeable?'
                    />
                  </Grid> */}
                  {/* 
                  {isChangeable && (
                    <Grid item xs={12} sm={4}>
                      <TextField
                        variant='outlined'
                        margin='normal'
                        fullWidth
                        id='points'
                        label='Puntos'
                        name='points'
                        type='number'
                        error={!!errors.points}
                        defaultValue={points}
                        inputRef={register({
                          required: {
                            value: isChangeable,
                            message:
                              "Los puntos son requeridos si es canjeable",
                          },
                          min: {
                            value: 0,
                            message: "Mínimo 0 puntos",
                          },
                        })}
                      />
                      <p>{errors?.points?.message}</p>
                    </Grid>
                  )} */}
                  {/* <Grid item xs={12} sm={6}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="ubication"
                      label="Ubicacion"
                      name="ubication"
                      autoComplete="name"
                      autoFocus
                      defaultValue={ubication}
                      error={!!errors.ubication}
                      inputRef={register({
                        required: {
                          value: true,
                          message: "La Ubicacion es requerido",
                        },
                        minLength: {
                          value: 1,
                          message: "Minimo 1 caracter",
                        },
                        maxLength: {
                          value: 255,
                          message: "Maximo 255 caracteres",
                        },
                      })}
                    />
                    <p>{errors?.ubication?.message}</p>
                  </Grid> */}
                  {/* <Grid item xs={12} sm={12} style={{ paddingTop: 16 }}>
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <SelectTypeProduct
                        detectarCambiosTypeProduct={detectarCambiosTypeProduct}
                        type_product={type_product}
                      />
                    </FormControl>
                  </Grid> */}
                </Grid>
                <Card profile>
                  <CardAvatar profile>
                    <img
                      src={image.urlPhoto}
                      alt='User'
                      width='100%'
                      height='100%'
                    />
                  </CardAvatar>
                  <CardBody profile>
                    <input
                      className={classes.input}
                      id='icon-button-file'
                      type='file'
                      name='image'
                      accept='image/png, image/jpg, image/jpeg'
                      onChange={handleChangeImage}
                    />
                    <label htmlFor='icon-button-file'>
                      <IconButton
                        color='primary'
                        aria-label='upload picture'
                        component='span'
                      >
                        <Tooltip
                          title='seleccionar imagen'
                          aria-label='seleccionar imagen'
                        >
                          <PhotoCamera />
                        </Tooltip>
                      </IconButton>
                    </label>
                    <InputLabel>Selecciona Imagen del Producto</InputLabel>
                    <h2 className={classes.cardTitle}>Editando Producto: </h2>
                    <h3 className={classes.cardTitle}></h3>
                    <p className={classes.description}>
                      Recuerda que la cantidad de este producto puede ser
                      modificada desde otros modulos
                    </p>
                  </CardBody>
                </Card>
                <Grid container spacing={3} justify='center'>
                  <Grid item spacing={3} xs={12} sm={12} md={6}>
                    <Button
                      type='submit'
                      fullWidth
                      variant='outlined'
                      className={classes.submit}
                    >
                      ACTUALIZAR
                    </Button>
                  </Grid>
                </Grid>
                {/**
                 * identificar Codigo de FORMS
                 */}
              </CardBody>
            </Card>
          </GridItem>
          {/**
           * identificar Codigo de FORMS
           */}
          {/**
           * identificar Codigo de FORMS
           */}
        </Grid>
        {/**
         * identificar Codigo de FORMS 123456789
         */}
      </form>
    </LayoutDashboard>
  );
}
export default withRouter(ProductEdit);
