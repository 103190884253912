import React, { useState, useEffect, Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import MonetizationOn from "@material-ui/icons/MonetizationOn";
import AccountBalance from "@material-ui/icons/AccountBalance";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { green } from "@material-ui/core/colors";
import IconButton from "@material-ui/core/IconButton";
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import CouponForm from "./CouponForm";

import { Tooltip } from "@material-ui/core";

import InputLabel from "@material-ui/core/InputLabel";
const theme = createMuiTheme({
  palette: {
    primary: green,
  },
});

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  input: {
    display: "none",
  },
  couponInput: {
    marginLeft: 7,
    padding: "5px",
    borderRadius: "4px",
    marginRight: "5px",
    // width: "100%",
    font: "inherit",
    height: "2.1876em",
    display: "block",
    boxSizing: "border-box", // Cambiado para un mejor control del tamaño
    border: "1px solid #c1c1c1",
    "&:hover": {
      border: "1px solid #44b700",
    },
  },
  inputContainer: {
    display: 'flex',
    justifyContent: 'center', // Centrar horizontalmente
    alignItems: 'center', // Centrar verticalmente
  },
  buttonDiscountCoupon: {
    color: "#fff",
    background: "#44b700",
    border: "none",
    borderRadius: "10px",
    fontSize: "15px",
    padding: "10px",
    "&:hover": {
      background: "#44b900",
    },
  },
  couponInput: {
    padding: "5px",
    borderRadius: "4px",
    marginRight: "5px",
    font: "inherit",
    height: "2.1876em",
    border: "1px solid #c1c1c1",
    "&:hover": {
      border: "1px solid #44b700",
    },
  },
}));

export default function ModalPaymentMethod(props) {
  const classes = useStyles();
  const {
    open,
    handleClose,
    obtenerInformacionPago,
    totalDescuento,
    informacionMetodoPago,
    generarVenta,
    handleChangeImage,
    handleChangeImageTransfer,
    image,
    imageTransfer,
    guardarTotalDescuento,
    subtotal,
    saveCouponInfo,
    couponInfo,
    branch,
    disableButton,
    saveComission,
    comission,
    setDiscountPoints,
    dispPoints,
    isAnyCheckboxSelected,
    setDiscountByPoints,
  } = props;

  const [mensaje, guardarMensaje] = useState("");
  const [error, guardarError] = useState(false);
  const [totalSubDiscount, saveTotalSubDiscount] = useState(0);
  const [couponDiscount, saveCouponDiscount] = useState("");
  const [disableCouponButton, saveDisableCouponButton] = useState(false);
  const [validationComission, saveValidationComission] = useState(false);
  const [puntosD, savePuntosD] = useState(0);
  const [discountByPoints, saveDiscountByPoints] = useState(0);
  const [inputPoints, setInputPoints] = useState('');
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  informacionMetodoPago.amount = Number(informacionMetodoPago.cash_income) + Number(informacionMetodoPago.card_income) + Number(informacionMetodoPago.transfer_income);
  useEffect(() => {
    setDiscountByPoints(discountByPoints);
  }, [discountByPoints, setDiscountByPoints]);
  useEffect(() => {
    saveCouponDiscount("");
    saveTotalSubDiscount(totalDescuento);
    saveCouponInfo("");
  }, [subtotal]);
  useEffect(() => {
    if (couponDiscount != "" && couponInfo != "") {
      let descuentoPorcentajeNew = couponDiscount;
      saveTotalSubDiscount(totalDescuento);
      const descuento = (descuentoPorcentajeNew * totalDescuento) / 100;
      const TotalDescuento = totalDescuento - descuento;
      guardarTotalDescuento(Math.round(TotalDescuento));
    }
  }, [couponDiscount, couponInfo]);

  useEffect(() => {
    AlertaCantidad();
    if (Number(informacionMetodoPago.card_income) > 0 && !validationComission) {
      saveComission(0);
      guardarTotalDescuento(Number(totalDescuento));
      saveValidationComission(true);
    }
    if (Number(informacionMetodoPago.card_income) <= 0 && validationComission) {
      saveValidationComission(false);
      guardarTotalDescuento(Number(totalDescuento) - comission);
      saveComission(0);
    }
  }, [informacionMetodoPago]);
  useEffect(() => {
    saveDiscountByPoints(Math.round(puntosD*0.01));
  }, [puntosD])
  useEffect(() => {
    guardarTotalDescuento(Math.round(totalDescuento-discountByPoints));
  }, [discountByPoints])
  
  const AlertaCantidad = () => {
    if (informacionMetodoPago.cash_income > totalDescuento) {
      if (
        Number(informacionMetodoPago.card_income) > 0 ||
        Number(informacionMetodoPago.transfer_income) > 0
      ) {
        guardarError(true);
        guardarMensaje("El monto no puede ser mayor al total a pagar");
      } else {
        guardarError(false);
      }
    } else {
      if (totalDescuento < informacionMetodoPago.amount) {
        guardarError(true);
        guardarMensaje("El monto no puede ser mayor al total a pagar");
      } else if (informacionMetodoPago.amount < 0) {
        guardarError(true);
        guardarMensaje("El monto no puede contener valores negativos");
      } else {
        guardarError(false);
      }
    }
  };

  if (informacionMetodoPago.cash_income > totalDescuento) {
    informacionMetodoPago.change =
      informacionMetodoPago.cash_income - totalDescuento;
  }

  const total = new Intl.NumberFormat("es-MX", {
    style: "currency",
    currency: "MXN",
    minimumFractionDigits: 2,
  }).format(totalDescuento);

  const total_amount = new Intl.NumberFormat("es-MX", {
    style: "currency",
    currency: "MXN",
    minimumFractionDigits: 2,
  }).format(informacionMetodoPago.amount);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputPoints(value);
  };
  
  const handleSavePoints = () => {
    const numericValue = inputPoints === '' ? 0 : Number(inputPoints);
    if (!isNaN(numericValue) && numericValue >= 0 && numericValue <= dispPoints) {
      setDiscountPoints(numericValue);
      savePuntosD(numericValue);
      setIsButtonDisabled(true);
    } else {
      alert('Por favor, ingresa un valor válido de puntos.');
    }
  };

  return (
    <Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
            id="alert-dialog-title"
            style={{
              textAlign: "center",
              background: "#3F51B5",
              color: "white",
            }}
          >
            {"Realizar Pago"}
        </DialogTitle>
        <Grid Container spacing={2}>
          <Grid item xs={12} style={{ marginTop: 15 }}>
            <CouponForm
              saveCouponDiscount={saveCouponDiscount}
              saveCouponInfo={saveCouponInfo}
              saveDisableCouponButton={saveDisableCouponButton}
              disableCouponButton={disableCouponButton}
              branch={branch}
              couponDiscount={couponDiscount}
            />
          </Grid> 
          {/* si hay puntos y no se selecciona producto a cangear */}
          {dispPoints> 0 && !isAnyCheckboxSelected ?(  
            <Grid container xs={12} md={12} xl={12} spacing={1} alignItems="center">
              <Grid item xs={6} md={6} xl={6} style={{paddingLeft: 12, marginRight: -25}}>
                <h4>Puntos disponibles: {dispPoints}</h4>
              </Grid>
              <Grid item xs={6} md={6} xl={6}>
                <Grid container alignItems="center">
                  <Grid item xs={8} style={{paddingRight:10}}> 
                    <input
                      className={classes.couponInput}
                      id="code"
                      type="text"
                      name="code"
                      placeholder="Descontar puntos"
                      value={inputPoints}
                      onChange={handleInputChange}
                      disabled={isButtonDisabled}
                      style={{ width: '100%' }} 
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <button
                      className={classes.buttonDiscountCoupon}
                      onClick={handleSavePoints}
                      disabled={isButtonDisabled}
                      style={{ width: '100%' }} 
                    >
                      Descontar
                    </button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ): null}       
        </Grid>
        <form onSubmit={generarVenta}>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Grid Container spacing={2}>
                <Grid item xs={12}>
                  {couponInfo != "" && (
                    <>
                      <h3>Total : ${totalSubDiscount} </h3>
                      <h3>Descuento: {couponInfo.discount}%</h3>
                    </>
                  )}
                  {discountByPoints ? 
                    <h3>Descuento por puntos: ${discountByPoints} </h3>
                  : null}
                  <h3>Total A Pagar : {total} </h3>
                  <h3>Total Pagado : {total_amount} </h3>
                  {informacionMetodoPago.cash_income > totalDescuento && (
                    <h3>Cambio: ${informacionMetodoPago.change}</h3>
                  )}
                </Grid>
                <Grid container>
                  <ThemeProvider theme={theme}>
                    <Grid item xs={4} style={{ padding: 10 }}>
                      <label>Ingresa Monto (Efectivo)</label>
                      <TextField
                        className={classes.margin}
                        id="cash_income"
                        placeholder="Efectivo"
                        fullWidth
                        type="number"
                        name="cash_income"
                        variant="outlined"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <MonetizationOn />
                            </InputAdornment>
                          ),
                        }}
                        onChange={(e) => obtenerInformacionPago(e)}
                      />
                      {error ? <p style={{ color: "red" }}>{mensaje}</p> : null}
                    </Grid>

                    <Grid item xs={4} style={{ padding: 10 }}>
                      <label>Ingresa Monto (Tarjeta)</label>
                      <TextField
                        className={classes.margin}
                        id="card_income"
                        placeholder="Tarjeta"
                        fullWidth
                        name="card_income"
                        variant="outlined"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <MonetizationOn />
                            </InputAdornment>
                          ),
                        }}
                        onChange={(e) => obtenerInformacionPago(e)}
                      />
                      {error ? <p style={{ color: "red" }}>{mensaje}</p> : null}

                      {informacionMetodoPago.card_income > 0 && (
                        <Fragment>
                          <div>
                            <InputLabel>
                              Selecciona Imagen del Ticket
                            </InputLabel>
                            <input
                              className={classes.input}
                              id="icon-button-file"
                              type="file"
                              name="image"
                              accept="image/png, image/jpg, image/jpeg"
                              onChange={handleChangeImage}
                            />
                            <label htmlFor="icon-button-file">
                              <IconButton
                                color="primary"
                                aria-label="upload picture"
                                component="span"
                              >
                                <Tooltip
                                  title="seleccionar imagen"
                                  aria-label="seleccionar imagen"
                                >
                                  <PhotoCamera />
                                </Tooltip>
                              </IconButton>
                            </label>
                          </div>
                          <img src={image.urlPhoto} alt="User" width="200px" />
                        </Fragment>
                      )}
                    </Grid>

                    <Grid item xs={4} style={{ padding: 10 }}>
                      <label>Ingresa Monto (Transferencia)</label>
                      <TextField
                        className={classes.margin}
                        id="transfer_income"
                        placeholder="Transferencia"
                        fullWidth
                        type="number"
                        name="transfer_income"
                        variant="outlined"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <MonetizationOn />
                            </InputAdornment>
                          ),
                        }}
                        onChange={(e) => obtenerInformacionPago(e)}
                      />
                      {error ? <p style={{ color: "red" }}>{mensaje}</p> : null}

                      {informacionMetodoPago.transfer_income > 0 && (
                        <Fragment>
                          <label>Ingresa referencia</label>
                          <TextField
                            className={classes.margin}
                            id="reference"
                            placeholder="Ingresa Referencia"
                            fullWidth
                            required
                            type="number"
                            variant="outlined"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <AccountBalance />
                                </InputAdornment>
                              ),
                            }}
                            name="reference"
                            onChange={(e) => obtenerInformacionPago(e)}
                          />
                          <div>
                            <InputLabel>
                              Selecciona Imagen del Ticket de Transferencia
                            </InputLabel>
                            <input
                              className={classes.input}
                              id="icon-button-file-transfer"
                              type="file"
                              name="image"
                              accept="image/png, image/jpg, image/jpeg"
                              onChange={handleChangeImageTransfer}
                            />
                            <label htmlFor="icon-button-file-transfer">
                              <IconButton
                                color="primary"
                                aria-label="upload picture"
                                component="span"
                              >
                                <Tooltip
                                  title="seleccionar imagen"
                                  aria-label="seleccionar imagen"
                                >
                                  <PhotoCamera />
                                </Tooltip>
                              </IconButton>
                            </label>
                          </div>
                          <img
                            src={imageTransfer.urlPhoto}
                            alt="User"
                            width="200px"
                          />
                        </Fragment>
                      )}
                    </Grid>
                  </ThemeProvider>
                </Grid>
              </Grid>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => handleClose()}
              color="secondary"
              variant="contained"
            >
              Cancelar
            </Button>
            {!disableButton ? (
              <Button
                type="submit"
                color="primary"
                autoFocus
                variant="contained"
              >
                Guardar
              </Button>
            ) : (
              <Button
                type="submit"
                disabled
                color="primary"
                autoFocus
                variant="contained"
              >
                Cargando...
              </Button>
            )}
          </DialogActions>
        </form>
      </Dialog>
    </Fragment>
  );
}
