import React, { useEffect, useState } from "react";
import LayoutDashboard from "../../Components/Layout/LayoutDashboard";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import MethodGet, { MethodPost } from "../../Config/Services";
import ModalImage from "react-modal-image";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Tooltip,
} from "@material-ui/core";
import Spinner from "../../Complements/Spinner";
import SvjPayment from "../../Complements/Image/DetailSale/payment.svg";
import DataClient from "../../Complements/Image/DetailSale/DataClient.svg";
import ButtonBase from "@material-ui/core/ButtonBase";
import Hidden from "@material-ui/core/Hidden";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import ModalPayments from "../Sales/ModalPaymentsRest";
import ErrorIcon from "@material-ui/icons/Error";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import headerConfig from "../../Config/imageHeaders";
import Swal from "sweetalert2";
import fileDownload from "js-file-download";
import clienteAxios from "../../Config/Axios";
import PictureAsPdf from "@material-ui/icons/PictureAsPdf";
import MaterialTable from "material-table";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(11),
    paddingBottom: theme.spacing(1),
    paddingInlineEnd: theme.spacing(3),
    paddingRight: theme.spacing(1),
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginBlockEnd: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  input: {
    display: "none",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  paperDetalle: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  paperTitle: {
    background: "#9966cc",
  },
  formControl: {
    margin: theme.spacing(1),
    width: "100%",
  },
  papercard: {
    maxWidth: "auto",
    backgroundColor: "#fff",
  },
  image: {
    width: "200px",
    height: "250px",
  },
  ticketimage: {
    width: 100,
    height: 120,
    maxWidth: 100,
    maxHeight: 120,
  },
  img: {
    margin: "auto",
    display: "flex",
    maxWidth: "100%",
    maxHeight: "100%",
  },
  papercardtitle: {
    background: "#6f33ab",
    //background: '#9966cc',
    padding: 7,
    fontSize: "21pt",
    color: "white",
  },
  Tblpapercard: {
    padding: theme.spacing(3),
    margin: "auto",
    Width: "100%",
    backgroundColor: "#fff",
  },
  textStructure: {
    borderBottom: "1px solid grey",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
}));

export default function DetailsSales(props) {
  const { id } = props.match.params;
  const classes = useStyles();

  const [details, saveDetail] = useState([]);
  const [products, saveProducts] = useState([]);
  const [productFree, saveProductFree] = useState(null);
  const [cupon, saveCupon] = useState(null);
  const [payments, savePayments] = useState([]);
  const [cargando, spinnerCargando] = useState(false);
  const [saleId, saveSaleId] = useState([]);
  const [sales, saveSale] = useState([]);
  const [data, setData] = useState({});
  const [r, setR] = useState(false);
  const [disableButton, setDisabledButton] = useState(false);
  const [image, setImage] = useState({
    urlPhoto: "https://pisouniversitario.com/template/images/page-404.jpg",
    image: "",
  });
  const [open, setOpen] = useState(false);
  const handleChangeImage = (e) => {
    setImage({
      ...image,
      urlPhoto: URL.createObjectURL(e.target.files[0]),
      image: e.target.files[0],
    });
  };
  const AddInPayment = (data) => {
    const formData = new FormData();
    formData.append("card_income", data.card_income);
    formData.append("cash_income", data.cash_income);
    formData.append("transfer_income", data.transfer_income);
    if (data.card_income > 0) {
      formData.append("image", data.image);
    }
    if (data.transfer_income > 0) {
      formData.append("reference", data.reference);
    }
    if (data.change > 0) {
      formData.append("change", data.change);
    }
    let url = `addPayments/${sales.id}`;
    MethodPost(url, formData, { headerConfig })
      .then((response) => {
        let folio = response.data.data.folio;
        let timerInterval;
        Swal.fire({
          title: "Pago Registrado",
          html: "El ticket se esta generando",
          icon: "success",
          timer: 3000,
          showConfirmButton: false,
          timerProgressBar: true,
          willClose: () => {
            clearInterval(timerInterval);
          },
        }).then((result) => {
          //Generar tiket de pago
          clienteAxios
            .get(`/ticketp/${sales.id}`, { responseType: "blob" })
            .then((response) => {
              let pdfname = "AbonoVenta" + folio + ".pdf";
              fileDownload(response.data, pdfname);
            })
            .catch((error) => {
              if (error.response.status === 409) {
                Swal.fire({
                  icon: "error",
                  title:
                    "Error inesperado. Si persiste, contacta con el administrador",
                  text: "No cuentas con suficiente información para generar reporte",
                });
                return;
              } else if (error.response.status === 422) {
                console.log(error);
                return;
              }
              return;
            });
        });
        setR(true);
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.error,
          icon: "error",
        });
      });
  };
  const addPayment = (e) => {
    if (
      totalDescuento < informacionMetodoPago.amount &&
      informacionMetodoPago.cash_income < totalDescuento
    ) {
      toast.warn(
        <div>
          <ErrorIcon /> {"El monto no puede ser mayor al total a pagar"}
        </div>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      return;
    } else if (informacionMetodoPago.amount < 0) {
      toast.warn(
        <div>
          <ErrorIcon /> {"No se permiten números negativos"}
        </div>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      return;
    } else if (informacionMetodoPago.amount == 0) {
      toast.warn(
        <div>
          <ErrorIcon /> {"Completa correctamente los datos"}
        </div>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      return;
    } else if (informacionMetodoPago.card_income > 0) {
      if (image.image == "") {
        toast.warn(
          <div>
            <ErrorIcon /> {"Selecciona ticket"}
          </div>,
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
        return;
      }
    } else if (informacionMetodoPago.transfer_income > 0) {
      if (informacionMetodoPago.reference == "") {
        toast.warn(
          <div>
            <ErrorIcon /> {"Debes introducir una referencia válida"}
          </div>,
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
        return;
      }
    }
    setDisabledButton(true);
    data.id = saleId;
    data.cash_income = informacionMetodoPago.cash_income;
    data.card_income = informacionMetodoPago.card_income;
    data.transfer_income = informacionMetodoPago.transfer_income;
    data.image = image.image;
    data.change = informacionMetodoPago.change;
    data.reference = informacionMetodoPago.reference;
    data.invoice = informacionMetodoPago.invoice;
    AddInPayment(data);
    handleClose();
  };
  useEffect(() => {
    let url = `/details/${id}`;
    MethodGet(url)
      .then((res) => {
        saveDetail(res.data.details);
        saveSaleId(res.data.details.saleId);
        saveProducts(res.data.products);
        savePayments(res.data.payments);
        saveProductFree(res.data.productFree);
        saveCupon(res.data.cupon);
        spinnerCargando(true);
      })
      .catch((error) => {
        console.log(error);
        spinnerCargando(true);
      });
  }, []);

  const handleClose = () => {
    guardarMetodoPago({
      amount: "",
      change: "0",
      cash_income: "0",
      card_income: "0",
      transfer_income: "0",
    });
    setOpen(false);
    setDisabledButton(false);
  };
  useEffect(() => {
    MethodGet(`/sales/${saleId}`)
      .then((res) => {
        saveSale(res.data.data);
        spinnerCargando(true);
      })
      .catch((error) => {
        console.log(error);
        spinnerCargando(true);
      });
  }, [saleId]);

  const subtotal = new Intl.NumberFormat("es-MX", {
    style: "currency",
    currency: "MXN",
    minimumFractionDigits: 2,
  }).format(details.subtotal);

  const infodiscount = new Intl.NumberFormat("es-MX", {
    style: "currency",
    currency: "MXN",
    minimumFractionDigits: 2,
  }).format(details.subtotal - details.total);

  const [informacionMetodoPago, guardarMetodoPago] = useState({
    amount: "0",
    change: "0",
    cash_income: "0",
    card_income: "0",
    transfer_income: "0",
    reference: "",
    invoice: "",
  });
  const handleClickOpen = () => {
    setOpen(true);
  };
  const [currentSale, SaveCurrentSale] = useState(0);
  const [totalPagado, guardarTotalPagado] = useState(0);
  const [totalDescuento, guardarTotalDescuento] = useState(0);
  const SelectSalePayment = (saleId, total, total_paid) => {
    SaveCurrentSale(saleId);
    guardarTotalDescuento(total);
    guardarTotalPagado(total_paid);
    setOpen(true);
  };
  const obtenerInformacionPago = (e) => {
    guardarMetodoPago({
      ...informacionMetodoPago,
      [e.target.name]: e.target.value,
    });
  };
  const generarTicket = (id) => {
    Swal.fire({
      title: "¿Estás seguro?",
      text: "¿Desea Generar El Ticket De Gasto?",
      type: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí, generar",
      cancelButtonText: "No, cancelar",
    }).then((result) => {
      if (result.value) {
        let timerInterval;
        Swal.fire({
          title: "Generando",
          html: "El ticket se está generando",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          willClose: () => {
            clearInterval(timerInterval);
          },
        });
        //Cunsume API de Back-end en laravel para generar tiket de venta
        clienteAxios
          .get(`/ticketpayment/${id}`, { responseType: "blob" })
          .then((response) => {
            fileDownload(response.data, "TicketPago " + id + ".pdf");
          })
          .catch((error) => {
            if (error.response.status === 409) {
              Swal.fire({
                icon: "error",
                title:
                  "Error inesperado. Si persiste, contacta con el administrador",
                text: "No cuentas con suficiente información para generar reporte",
              });
            }
          });
      }
    });
  };

  const formatCurrency = (value) => {
    return new Intl.NumberFormat("es-MX", {
      style: "currency",
      currency: "MXN",
      minimumFractionDigits: 0,
    }).format(value);
  };

  const InfoItem = ({ label, value }) => (
    <>
      <Typography variant='subtitle2' gutterBottom>
        {label}
      </Typography>
      <Typography variant='body2'>{value}</Typography>
    </>
  );

  //  spinner de carga
  if (!cargando) return <Spinner />;

  return (
    <LayoutDashboard>
      <Grid container>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          justify='center'
          style={{
            background: "linear-gradient(50deg, #6F33AB 33%, indigo 100%)",
            color: "white",
            marginTop: 80,
            borderRadius: 15,
            paddingLeft: 25,
            paddingRight: 25,
            marginBottom: 10,
          }}
        >
          <Typography
            component='h1'
            variant='h4'
            align='center'
            className={classes.typography}
          >
            Detalle de ventas
          </Typography>
        </Grid>
      </Grid>
      <Container>
        <Grid container xs={12} sm={12} md={12} lg={12} xl={12}>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            style={{ padding: 10 }}
          >
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Typography
                component='h1'
                variant='h4'
                align='center'
                className={classes.papercardtitle}
              >
                Información de venta
              </Typography>
            </Grid>
            <Paper className={classes.papercard}>
              <Grid
                item
                xs={12}
                sm={12}
                md={7}
                lg={7}
                xl={7}
                container
                style={{ marginLeft: 20 }}
              >
                <Grid item container xs={12} sm={12} md={12} lg={5} xl={5}>
                  <Typography component='h6' variant='h6'>
                    Folio:
                  </Typography>
                  <Typography
                    component='h6'
                    variant='h6'
                    color='textSecondary'
                    alignItems='stretch'
                  >
                    {details.folio}
                  </Typography>
                </Grid>
                <Grid item container xs={12} sm={12} md={12} lg={7} xl={7}>
                  <Typography component='h6' variant='h6'>
                    Fecha:
                  </Typography>
                  <Typography
                    component='h6'
                    variant='h6'
                    color='textSecondary'
                    alignItems='stretch'
                  >
                    {details.fechaventa}
                  </Typography>
                </Grid>
                <Grid item container>
                  <Typography component='h6' variant='h6'>
                    Cantidad de Productos:
                  </Typography>
                  <Typography
                    component='h6'
                    variant='h6'
                    color='textSecondary'
                    alignItems='stretch'
                  >
                    {details.cantidad}
                  </Typography>
                </Grid>
                <Grid item container>
                  <Typography component='h6' variant='h6'>
                    Subtotal:
                  </Typography>
                  <Typography
                    component='h6'
                    variant='h6'
                    color='textSecondary'
                    alignItems='stretch'
                  >
                    {subtotal}
                  </Typography>
                </Grid>
                {details.discount ? (
                  <Grid item container>
                    <Grid
                      item
                      container
                      xs={10}
                      sm={10}
                      md={10}
                      lg={10}
                      xl={10}
                    >
                      <Typography component='h6' variant='h6'>
                        Descuento:
                      </Typography>
                      <Typography
                        component='h6'
                        variant='h6'
                        color='textSecondary'
                        alignItems='stretch'
                      >
                        {infodiscount}
                      </Typography>
                    </Grid>
                    <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                      <Typography
                        component='h6'
                        variant='h6'
                        color='textSecondary'
                        style={{ color: "red" }}
                      >
                        {details.discount}%
                      </Typography>
                    </Grid>
                  </Grid>
                ) : null}

                <Grid item container>
                  <Grid item container xs={10} sm={10} md={10} lg={10} xl={10}>
                    <Typography component='h6' variant='h6'>
                      Cupón:
                    </Typography>
                    <Typography
                      component='h6'
                      variant='h6'
                      color='textSecondary'
                      alignItems='stretch'
                    >
                      {cupon ? cupon.code : "n/A"}
                    </Typography>
                  </Grid>

                  <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                    <Typography
                      component='h6'
                      variant='h6'
                      color='textSecondary'
                      style={{ color: "red" }}
                    >
                      {cupon ? cupon.discount : 0}%
                    </Typography>
                  </Grid>
                </Grid>

                {details.discountPoints ? (
                  <Grid item container xs={10} sm={10} md={10} lg={10} xl={10}>
                    <Typography component='h6' variant='h6'>
                      Descuento por puntos:
                    </Typography>
                    <Typography
                      component='h6'
                      variant='h6'
                      color='textSecondary'
                      alignItems='stretch'
                    >
                      ${details.discountPoints}
                    </Typography>
                  </Grid>
                ) : null}
                <Grid item container>
                  <Typography component='h6' variant='h6'>
                    Total:
                  </Typography>
                  <Typography
                    component='h6'
                    variant='h6'
                    color='textSecondary'
                    alignItems='stretch'
                  >
                    {new Intl.NumberFormat("es-MX", {
                      style: "currency",
                      currency: "MXN",
                      minimumFractionDigits: 2,
                    }).format(details.total)}
                  </Typography>
                </Grid>
                <Grid item container>
                  <Typography component='h6' variant='h6'>
                    Recibimos:
                  </Typography>
                  <Typography
                    component='h6'
                    variant='h6'
                    color='textSecondary'
                    alignItems='stretch'
                  >
                    {new Intl.NumberFormat("es-MX", {
                      style: "currency",
                      currency: "MXN",
                      minimumFractionDigits: 2,
                    }).format(sales.total_paid)}
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          {/**
           ***** Tbl Datos del Cliente
           */}
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            style={{ padding: 10 }}
          >
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Typography
                component='h1'
                variant='h4'
                align='center'
                className={classes.papercardtitle}
              >
                Datos del Cliente
              </Typography>
            </Grid>
            <Paper className={classes.papercard}>
              <Grid
                item
                xs={12}
                sm={12}
                md={7}
                lg={7}
                xl={7}
                continer
                style={{ marginLeft: 20 }}
              >
                <Grid item container>
                  <Typography component='h6' variant='h6'>
                    Nombre:
                  </Typography>
                  <Typography
                    component='h6'
                    variant='h6'
                    color='textSecondary'
                    alignItems='stretch'
                  >
                    {details.nombre_cliente} {details.apellidop_cliente}{" "}
                    {details.apellidomat_cliente}
                  </Typography>
                </Grid>
                <Grid item container>
                  <Typography component='h6' variant='h6'>
                    Teléfono:
                  </Typography>
                  <Typography
                    component='h6'
                    variant='h6'
                    color='textSecondary'
                    alignItems='stretch'
                  >
                    {details.telefono_cliente
                      ? details.telefono_cliente
                      : `No registrado`}
                  </Typography>
                </Grid>
                <Grid item container>
                  <Typography component='h6' variant='h6'>
                    RFC:
                  </Typography>
                  <Typography
                    component='h6'
                    variant='h6'
                    color='textSecondary'
                    alignItems='stretch'
                  >
                    {details.client_rfc ? details.client_rfc : `No registrado`}
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
        {/**
         ***** Tbl Detalle de Productos
         */}

        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography
              component='h1'
              variant='h4'
              align='center'
              className={classes.papercardtitle}
            >
              Detalle de compra del cliente
            </Typography>
          </Grid>
          <Hidden only={["xs", "sm"]}>
            <MaterialTable
              title={""}
              columns={[
                { title: "Clave", field: "tag" },
                { title: "Nombre", field: "name" },
                { title: "Descripcion", field: "description" },
                {
                  title: "Precio",
                  field: "price",
                  render: (rowData) =>
                    new Intl.NumberFormat("es-MX", {
                      style: "currency",
                      currency: "MXN",
                      minimumFractionDigits: 0,
                    }).format(rowData.price),
                },
                { title: "Cantidad", field: "quantity" },
                {
                  title: "Tipo",
                  field: "tag",
                  render: (rowData) => (
                    <>
                      {rowData.type === 1
                        ? "Mixtos"
                        : rowData.type === 2
                        ? "Wapizima"
                        : rowData.type === 3
                        ? "Extra"
                        : "General"}
                    </>
                  ),
                },
              ]}
              data={products}
              options={{
                headerStyle: {
                  backgroundColor: "none",
                  color: "#000",
                  fontSize: "16px",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                },

                actionsColumnIndex: -1,
              }}
              localization={{
                pagination: {
                  labelDisplayedRows: "{from} - {to} de {count}",
                  labelRowsSelect: "Columnas",
                  firstTooltip: "Primera Pagina",
                  previousTooltip: "Anterior",
                  nextTooltip: "Siguiente",
                  lastTooltip: "Ultima Página",
                },
                toolbar: {
                  searchTooltip: "Buscar",
                  searchPlaceholder: "Buscar",
                },
                body: {
                  emptyDataSourceMessage: "No Hay Productos que Mostrar",
                  filterRow: {
                    filterTooltip: "Buscar",
                  },
                },
              }}
            />
          </Hidden>
          <Hidden only={["md", "lg", "xl"]}>
            <MaterialTable
              title={""}
              columns={[
                { title: "Clave", field: "tag" },
                { title: "Nombre", field: "name" },
              ]}
              data={products}
              options={{
                headerStyle: {
                  backgroundColor: "none",
                  color: "#000",
                  fontSize: "16px",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                },

                actionsColumnIndex: -1,
              }}
              detailPanel={[
                {
                  tooltip: "Show",
                  render: (rowData) => {
                    return (
                      <div
                        style={{
                          color: "black",
                          marginLeft: 50,
                        }}
                      >
                        <p>Descripcion: {rowData.description}</p>
                        <p>
                          Precio:{" "}
                          {new Intl.NumberFormat("es-MX", {
                            style: "currency",
                            currency: "MXN",
                            minimumFractionDigits: 0,
                          }).format(rowData.price)}
                        </p>
                        <p>Cantidad: ${rowData.quantity}</p>
                        <p>
                          Tipo:{" "}
                          {
                            <>
                              {rowData.type === 1
                                ? "Mixtos"
                                : rowData.type === 2
                                ? "Wapizima"
                                : rowData.type === 3
                                ? "Extra"
                                : "General"}
                            </>
                          }
                        </p>
                      </div>
                    );
                  },
                },
              ]}
              localization={{
                pagination: {
                  labelDisplayedRows: "{from} - {to} de {count}",
                  labelRowsSelect: "Columnas",
                  firstTooltip: "Primera Pagina",
                  previousTooltip: "Anterior",
                  nextTooltip: "Siguiente",
                  lastTooltip: "Ultima Página",
                },
                toolbar: {
                  searchTooltip: "Buscar",
                  searchPlaceholder: "Buscar",
                },
                body: {
                  emptyDataSourceMessage: "No Hay Productos que Mostrar",
                  filterRow: {
                    filterTooltip: "Buscar",
                  },
                },
              }}
            />
          </Hidden>
        </Grid>

        {productFree ? (
          <>
            <Card sx={{ maxWidth: 800, margin: "auto", mt: 4 }}>
              <CardHeader
                title='Producto cambiado'
                titleTypographyProps={{ align: "center" }}
                className={classes.papercardtitle}
              />
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={6} sm={3} md={3}>
                    <InfoItem label='Clave' value={productFree.tag} />
                  </Grid>
                  <Grid item xs={6} sm={3} md={3}>
                    <InfoItem label='Nombre' value={productFree.name} />
                  </Grid>
                  <Grid item xs={6} sm={3} md={3}>
                    <InfoItem
                      label='Descripción'
                      value={productFree.description}
                    />
                  </Grid>
                  <Grid item xs={6} sm={3} md={3}>
                    <InfoItem
                      label='Precio'
                      value={formatCurrency(productFree.price)}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </>
        ) : null}

        {/**
         ***** Tbl Datos del Cliente
         */}

        <Grid container xs={12} sm={12} md={12} lg={12} xl={12}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            {sales.total > sales.total_paid ? (
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography
                  component='h1'
                  variant='h4'
                  align='center'
                  className={classes.papercardtitle}
                >
                  Pagos realizados
                </Typography>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  align='right'
                  style={{ marginRight: 30, marginTop: -30 }}
                >
                  <Button
                    variant='contained'
                    style={{ textTransform: "none", background: "white" }}
                    size='small'
                    type='submit'
                    onClick={() => {
                      SelectSalePayment(
                        sales.id,
                        sales.total,
                        sales.total_paid
                      );
                    }}
                  >
                    <Tooltip title='Agregar Pago' aria-label='Agregar Pago'>
                      <MonetizationOnIcon style={{ color: "indigo" }} />
                    </Tooltip>
                    Realizar pago
                  </Button>
                </Grid>
              </Grid>
            ) : (
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography
                  component='h1'
                  variant='h4'
                  align='center'
                  className={classes.papercardtitle}
                >
                  Pagos realizados
                </Typography>
              </Grid>
            )}
            <Hidden only={["xs", "sm"]}>
              <MaterialTable
                title={""}
                columns={[
                  { title: "Fecha", field: "fecha" },
                  { title: "Tipo de Pago", field: "name" },
                  {
                    title: "Monto",
                    field: "amount",
                    render: (rowData) =>
                      new Intl.NumberFormat("es-MX", {
                        style: "currency",
                        currency: "MXN",
                        minimumFractionDigits: 0,
                      }).format(Number(rowData.amount - sales.change)),
                  },
                  {
                    title: "Referencia",
                    field: "reference",
                    render: (rowData) => (
                      <>{rowData.reference ? rowData.reference : `No Aplica`}</>
                    ),
                  },
                  {
                    title: "Generar Ticket",
                    field: "generarTicket",
                    render: (rowData) => (
                      <Button
                        style={{ textTransform: "none" }}
                        size='small'
                        onClick={() => generarTicket(rowData.id)}
                      >
                        <Tooltip
                          title='Generar ticket de venta'
                          aria-label='Generar ticket de venta'
                        >
                          <PictureAsPdf style={{ color: "rgb(0, 0, 0)" }} />
                        </Tooltip>
                      </Button>
                    ),
                  },
                ]}
                data={payments}
                options={{
                  headerStyle: {
                    backgroundColor: "none",
                    color: "#000",
                    fontSize: "16px",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                  },
                  actionsColumnIndex: -1,
                }}
                localization={{
                  pagination: {
                    labelDisplayedRows: "{from} - {to} de {count}",
                    labelRowsSelect: "Columnas",
                    firstTooltip: "Primera Pagina",
                    previousTooltip: "Anterior",
                    nextTooltip: "Siguiente",
                    lastTooltip: "Ultima Página",
                  },
                  toolbar: {
                    searchTooltip: "Buscar",
                    searchPlaceholder: "Buscar",
                  },
                  body: {
                    emptyDataSourceMessage: "No Hay Productos que Mostrar",
                    filterRow: {
                      filterTooltip: "Buscar",
                    },
                  },
                }}
              />
            </Hidden>
            <Hidden only={["md", "lg", "xl"]}>
              <MaterialTable
                title={""}
                columns={[
                  { title: "Fecha", field: "fecha" },
                  { title: "Tipo de Pago", field: "name" },
                  {
                    title: "Monto",
                    field: "amount",
                    render: (rowData) =>
                      new Intl.NumberFormat("es-MX", {
                        style: "currency",
                        currency: "MXN",
                        minimumFractionDigits: 0,
                      }).format(rowData.amount),
                  },
                  {
                    title: "Referencia",
                    field: "reference",
                    render: (rowData) => (
                      <>{rowData.reference ? rowData.reference : `No Aplica`}</>
                    ),
                  },
                  {
                    title: "Generar Ticket",
                    field: "generarTicket",
                    render: (rowData) => (
                      <Button
                        style={{ textTransform: "none" }}
                        size='small'
                        onClick={() => generarTicket(rowData.id)}
                      >
                        <Tooltip
                          title='Generar ticket de venta'
                          aria-label='Generar ticket de venta'
                        >
                          <PictureAsPdf style={{ color: "rgb(0, 0, 0)" }} />
                        </Tooltip>
                      </Button>
                    ),
                  },
                ]}
                data={payments}
                options={{
                  headerStyle: {
                    backgroundColor: "none",
                    color: "#000",
                    fontSize: "16px",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                  },

                  actionsColumnIndex: -1,
                }}
                detailPanel={[
                  {
                    tooltip: "Show",
                    render: (rowData) => {
                      return (
                        <div
                          style={{
                            color: "black",
                            marginLeft: 50,
                          }}
                        >
                          <p>
                            Monto:{" "}
                            {new Intl.NumberFormat("es-MX", {
                              style: "currency",
                              currency: "MXN",
                              minimumFractionDigits: 0,
                            }).format(rowData.amount)}
                          </p>
                          <p>
                            Referencia:{" "}
                            {
                              <>
                                {rowData.reference
                                  ? rowData.reference
                                  : `No Aplica`}
                              </>
                            }
                          </p>
                        </div>
                      );
                    },
                  },
                ]}
                localization={{
                  pagination: {
                    labelDisplayedRows: "{from} - {to} de {count}",
                    labelRowsSelect: "Columnas",
                    firstTooltip: "Primera Pagina",
                    previousTooltip: "Anterior",
                    nextTooltip: "Siguiente",
                    lastTooltip: "Ultima Página",
                  },
                  toolbar: {
                    searchTooltip: "Buscar",
                    searchPlaceholder: "Buscar",
                  },
                  body: {
                    emptyDataSourceMessage: "No Hay Productos que Mostrar",
                    filterRow: {
                      filterTooltip: "Buscar",
                    },
                  },
                }}
              />
            </Hidden>
          </Grid>
        </Grid>

        <Grid container xs={12} sm={12} md={12} lg={12} xl={12} spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Typography
                component='h1'
                variant='h4'
                align='center'
                className={classes.papercardtitle}
              >
                Ticket de Compra
              </Typography>
            </Grid>
            <Paper className={classes.Tblpapercard}>
              <Grid container>
                {payments.map((payment) => (
                  <Grid
                    container
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    key={payment.id}
                    direction='row'
                    justify='space-around'
                    alignItems='center'
                  >
                    <Grid item container xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Grid item>
                        <Typography component='h5' variant='h5'>
                          {payment.name}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Grid item>
                        <Typography component='h5' variant='h5'>
                          {payment.ticket ? (
                            <ButtonBase className={classes.ticketimage}>
                              <ModalImage
                                small={payment.ticket}
                                large={payment.ticket}
                                alt='ticket'
                                className={classes.img}
                              />
                            </ButtonBase>
                          ) : payment.ticketTransfer ? (
                            <ButtonBase className={classes.ticketimage}>
                              <ModalImage
                                small={payment.ticketTransfer}
                                large={payment.ticketTransfer}
                                alt='ticketTransfer'
                                className={classes.img}
                              />
                            </ButtonBase>
                          ) : (
                            `No Aplica`
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Container>
      <ModalPayments
        open={open}
        setOpen={setOpen}
        handleClickOpen={handleClickOpen}
        handleClose={handleClose}
        totalDescuento={totalDescuento}
        totalPagado={totalPagado}
        informacionMetodoPago={informacionMetodoPago}
        image={image}
        obtenerInformacionPago={obtenerInformacionPago}
        addPayment={addPayment}
        handleChangeImage={handleChangeImage}
        disableButton={disableButton}
      />
    </LayoutDashboard>
  );
}
