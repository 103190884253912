import React, { useState, useEffect } from "react";
import Select from "react-select";

export default function SelectTypeSale(props) {
  const [error, guardarError] = useState(true);
  const [tipos, saveType] = useState([]);

  const typeSales = [
    { type_sale: 1, name: "Productos canjeables" },
    { type_sale: 2, name: "Venta Productos Wapizima" },
  ];

  useEffect(() => {
    saveType(typeSales);
    if (props.type_sale) {
      guardarError(false);
    }
  }, [error]);
  const detectarCambiosTypeSale = (value) => {
    props.detectarCambiosTypeSale(value);
  };
  const selectStyles = {
    menu: (base) => ({
      ...base,
      zIndex: 100,
    }),
  };
  return (
    <>
      <Select
        onChange={(value) => detectarCambiosTypeSale(value)}
        className="basic-single"
        classNamePrefix="select"
        name="client"
        styles={selectStyles}
        placeholder="Selecciona un tipo de venta"
        options={typeSales.map((typeSale) => {
          let attribute = {
            label: typeSale?.name,
            value: typeSale?.type_sale,
          };
          return attribute;
        })}
      />
    </>
  );
}
